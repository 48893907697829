export default {
  contentImageSideAndBottom: {
    top: {
      title: 'De pessoas para pessoas!',
      content:
        'Sabemos que as pessoas são nosso maior bem, por isso, cuidamos, acolhemos, formamos e desenvolvemos nosso time e, assim, garantimos entregas alinhadas às expectativas e que geram valor real para os clientes e para nós.',
    },

    bottom: {
      title: 'Onde Atuamos',
      content:
        'Nossa sede está localizada em São Paulo e temos especialistas em diversos lugares do país e do mundo. Por isso, estamos aptos a atender clientes em diferentes localidades e empresas de todos os setores e segmentos.',
      button: 'Fale com a Gente',
    },

    bottomGptw: {
      button: 'Saiba Mais',
    },
  },
}
