export default {
  banner: ['Desenvolvimento', 'Web, Mobile e Mainframe'],

  title: 'Mais de 20 anos de experiência',

  text1: 'Somos apaixonados por tecnologia!',

  text2:
    'O objetivo da SIS Innov & Tech é desenvolver projetos que contribuam para o crescimento da sua empresa. São mais de 20 anos de experiência no mercado, desenvolvendo sistemas que atendam às necessidades de cada cliente.',

  text3: 'Desenvolvimento Web e Mobile',

  text4:
    'O desenvolvimento de sistemas Web e Mobile busca implementar soluções personalizadas para cada cliente. Atuamos em todas as fases de desenvolvimento do projeto, desde a concepção até a entrega, e com diversas linguagens e frameworks.',

  text5:
    'Nossos profissionais estão em constante aprendizado e atualizados com as melhores práticas do mercado, para sempre proporcionar o melhor produto aos nossos clientes.',

  text6: 'Mainframe',

  text7: 'Total confiabilidade e desempenho',

  text8:
    'A SIS Innov & Tech é especialista em alta plataforma e no desenvolvimento e sustentação de sistemas de alta disponibilidade. Nossas soluções abrangem o processamento de grandes volumes de dados e permitem a estabilidade, segurança e confiabilidade das informações.',

  text9:
    'De forma ágil e segura, a vasta experiência em projetos mainframe nos garante orientar o cliente sobre os melhores caminhos a seguir para atender as necessidades compartilhadas. Seja para realizar melhorias nos processos existentes ou para desenvolver e reestruturar sistemas completos, conte com a SIS para os melhores resultados.',
}
