export default {
  banner: ['Design', 'Thinking'],

  title1: 'O ser humano centro',

  text1:
    'Na SIS, nos importamos com a valorização do ser humano. Nas metodologias de desenvolvimento adotadas não seria diferente. O Design Thinking é um processo não linear e interativo que nossas equipes usam para entender os usuários, desafiar suposições, redefinir problemas e criar soluções inovadoras para protótipos e testes.',

  title2: 'Empatizar, Definir, Idealizar, Prototipar e Testar',

  text2: 'A chave para a inovação',

  text3:
    'A meta da SIS com os projetos de Design Thinking é promover a inovação, canalizando esforços às suas necessidades, compreendendo seu mercado de performance e suas ferramentas para, assim, disseminar sua proposta de valor alinhada aos objetivos pertinentes. Agimos promovendo:',

  list: {
    item1: '✔ Solução dos dilemas e desafios;',
    item2: '✔ Modelagem lógica dos negócios;',
    item3: '✔ Propósito alinhado à inovação e ascensão do seu negócio;',
    item4: '✔ Desenvolvimento de equipes multidisciplinares e integradas;',
    item5: '✔ Desenvolvimento de produtos e serviços inovadores e voltados às necessidades do seu consumidor.',
  },

  text4: 'Consultoria especializada',

  text5:
    'A liderança orientada da SIS permite que os gestores conduzam da melhor maneira os pontos de suas organizações. O conhecimento técnico de consultoria especializada auxilia na cocriação de benefícios para o seu negócio, afirmando a administração ágil e eficiente que o Design Thinking oferece.',
}
