export default {
  banner: 'DevOps',
  title1: 'Liderando a transformação de suas operações',
  text1:
    'A cultura DevOps combina práticas, ferramentas e filosofias culturais que visam aumentar a capacidade das empresas de disponibilizar softwares e serviços, com maior agilidade que em processos convencionais, acelerando a entrega de valor ao cliente.',
  text2: 'Metodologias e ferramentas ágeis otimizando seu desenvolvimento!',
  button1: 'Quero otimizar meu negócio',
  title2: 'Nós transformamos seus processos de desenvolvimento ágil',
  text3:
    'Por meio de uma equipe experiente na aplicação de abordagens de infraestruturas, como código (IaC), integração contínua e entrega contínua (CI/CD), a SIS oferece serviços de ponta a ponta, baseados em metodologias e ferramentas modernas para oferecer aos clientes sempre a melhor experiência possível.',
  text4:
    'A SIS conhece todo o processo de desenvolvimento ágil de software e realiza um trabalho de assessment, que visa entender como funcionam os processos dos clientes sobre o desenvolvimento e a operação de softwares, tudo para entregar soluções eficientes para sua empresa.',
  text5: 'A SIS oferece soluções DevOps eficientes para sua empresa.',
  button2: 'Conheça agora!',
  title3: 'Leia mais sobre DevOps',
}
