export default {
  banner: ['Digital', 'Transformation'],

  title: 'Technology for corporate culture change',

  text1:
    'Digital transformation is a trend in the current market and has become necessary for companies to offer solutions that focus on innovative technologies, which are tailored to each client’s needs.',

  text2:
    'SIS plays a key role with its clients, offering development services, business analysis, testing, approval, and infrastructure, thus providing an entire structure focused on the digital transformation process.',

  text3:
    "Seeking to offer agile, innovative, and technological solutions, following the best practices and trends for the concept of digital transformation applied to the corporate universe, we focus on the client's business model with smart process implementation.",
}
