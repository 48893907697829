export default {
  banner: ['Servicios de', 'Cloud Computing'],

  title: 'Compartir de forma remota es seguro y ágil',

  text1:
    'El acceso a la nube surgió como una forma de democratizar informaciones y mejorar la experiencia de quien usa recursos tecnológicos, ya sea personal o profesionalmente.',

  text2:
    'SIS ofrece a los clientes un conjunto ideal de soluciones, que permiten implantar y gestionar directamente ambientes de TI en nube, con agilidad y seguridad.',

  text3:
    'La combinación perfecta de infraestructura y plataforma garantiza al usuario economía de espacio, centralización de la información y total seguridad.',
}
