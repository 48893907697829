export default {
  servicesProducts: {
    banner: '',
    clietsTitle: 'Nuestros principales clientes',
    partnersTitle: 'Socios y tecnologías',
    conectPeople: {
      title: '',
      text: '',
      footer: '',
    },
  },
}
