export default {
  banner: ['Serviços de', 'Cloud Computing'],

  title: 'Compartilhamento remoto, seguro e ágil',

  text1:
    'O acesso em nuvem surgiu como uma forma de democratizar informações e melhorar a experiência de quem lida com recursos tecnológicos, seja pessoal ou profissionalmente.',

  text2:
    'A SIS oferece aos clientes um conjunto ideal de soluções, que permitem implantar e gerir diretamente ambientes de TI em nuvem, com agilidade e segurança.',

  text3:
    'A combinação perfeita de infraestrutura e plataforma garante a economia de espaço, a centralização da informação e total segurança ao usuário.',
}
