import filters from './filters'

export default {
  blog: {
    title: ['SIS', 'News'],
    subtitle: 'Follow the news about the world of technology and SIS solutions',
    button: 'Read the full article',

    filters: {
      ...filters,
    },
  },
}
