export default {
  text1: 'Nombre',
  text2: 'Apellido',
  text3: 'Coloque su e-mail',
  text4: 'Teléfono',
  text5: ['¿Ud fue indicado para esa vacante?', 'No', 'Si'],
  text6: 'Nombre de quien lo indicó',
  text7: 'E-mail corporativo de quien lo indicó',
  text8: ['¿Ud. trabaja en SIS?', 'No', 'Si'],
  text9: 'Su email corporativo',
  text10: 'Suba suCV',
  text11: 'Enviar mi candidatura',

  talentOption: 'Tipo de vacante',
}
