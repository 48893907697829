export default {
  banner: ['Quality', 'Assurance'],

  title: 'Transformação digital com garantia de qualidade',

  text1:
    'Na SIS Innov & Tech, nos preocupamos com a qualidade e a eficiência do produto ou serviço entregue para cada cliente. Afinal, esses são critérios essenciais e indispensáveis. Assumimos o compromisso de assegurar a entrega do serviço aos usuários finais com o máximo de qualidade, garantindo a excelência desejada. Você encontra na SIS redução dos custos operacionais, otimização da rotina de trabalho e da antecipação de problemas, atuando com:',

  list: {
    item1: '✔ Consultoria em qualidade',
    item2: '✔ Automação de testes e RPA',
    item3: '✔ Fábrica de testes',
    item4: '✔ Testes funcionais',
    item5: '✔ Testes não funcionais (Performance, Usabilidade, Portabilidade, entre outros)',
    item6: '✔ Testes mobile, web, desktop e de serviços',
    item7: '✔ Testes de segurança (pentest, dast, sast, iast)',
    item8: '✔ E auditoria de código',
  },

  text2:
    'Assim, a Quality Assurance representa a melhor estratégia para evitar problemas, criando mecanismos para identificá-los e tratá-los com eficiência.',
}
