export default {
  banner: ['Sustentación', 'de Ambientes'],

  title: '¡Movidos a tecnología!',

  content: [
    'La sustentación de ambientes consiste en la gestión correctiva, adaptiva y evolutiva de los sistemas de tecnología utilizados diariamente en una empresa.',

    'Este servicio es extremamente útil y necesario, para evitar los perjuicios que errores,  panes o bugs puedan ocasionar a su negocio.',

    'El equipo de SIS está compuesto por profesionales capaces de comprender rápidamente las particularidades de los sistemas utilizados en su empresa, explorar todas las funcionalidades y atender prontamente los llamados abiertos.',

    'La gobernanza está muy presente, pues acompaña detalladamente los llamados, clasificándolos por nivel de gravedad, y hace la distribución de los servicios para los equipos, altamente preparados, tanto con relación a los aspectos técnicos, como a los aspectos del negocio de los clientes.',

    'Para que las expectativas de los clientes sean satisfechas, el trabajo se realiza de acuerdo con el nivel de servicio (SLA), cuyo objetivo es atender sus llamados, ya sean dudas o incidentes, solucionándolos dentro de un tiempo previamente definido, según su nivel de gravedad.',

    'Para dar plena transparencia a la ejecución de las actividades de sustentación, además del SLA, también se ofrece el seguimiento de los procesos y se emiten informes mensuales con todos los indicadores de esa operación.',
  ],
}
