export default {
  footer: {
    text1: 'Since 2003 integrating technology, processes, and people to meet the most challenging market demands.',

    text2: 'Privacy',

    text3: 'Terms of use',

    text4: 'Code of Conduct',

    text5: 'Services',

    text6: 'Strategy',

    text7: 'Digital Transformation',

    text8: 'Environment support',

    text9: 'Development',

    text10: 'Low Code Solutions',

    text11: 'Contact info',

    text12: 'contato@sis-it.com',

    text13: '+55 11 3711 6530',

    text14: 'Ombudsman',

    text15: 'Reporting',

    text16: '© 2021 SIS. All rights reserved.',

    text17: 'LGPD',

    text18: ['Follow our daily activities', 'on social media.'],

    text19: 'Cookies Policy',
  },
}
