export default {
  banner: ['User Experience e User Interface', '(UX/UI)'],

  title1: 'Você sabe de que maneira o UX afeta sua empresa?',

  text1:
    'A qualidade do seu UX é um dos fatores decisivos para um usuário escolher entre você e seu concorrente, afinal, o nível de ambição, os processos internos e todo um conjunto de ferramentas necessárias para fornecer a experiência certa moldam sua organização.',

  text2:
    'O UX Design é o catalisador para reunir o usuário, a tecnologia e a empresa, tudo isso por meio da criação de interfaces. Nossa meta é a entrega de um projeto que, interação por interação, torne a vida do usuário melhor.',

  title2: 'Além de funcional, o UI deixa tudo agradável',

  text3: 'Interação, usabilidade e visual: tudo aquilo que você espera!',

  text4:
    'O trabalho dos UI Designers da SIS representa tudo aquilo que é utilizado na interação com o produto. Eles são responsáveis pela criação de interfaces funcionais, que permitem que o usuário navegue com a melhor experiência por uma plataforma que vai muito além de sua beleza.',

  text5:
    'Nosso objetivo é a entrega final de uma interface, seja ela visual ou não, que garanta máxima interação, performance e usabilidade: entendemos a jornada do usuário, aplicamos e construímos os melhores caminhos em busca da excelência.',
}
