export default {
  banner: ['Soluções de', 'Low Code'],

  title1: 'Desenvolvimento de sistemas de forma ágil e simples',

  text1:
    'A transformação digital trouxe tecnologias e tendências que facilitam e aprimoram a gestão em TI. Como uma empresa que acompanha as movimentações e transformações do mercado, a SIS oferece aos seus clientes as soluções mais certeiras e eficazes para cada objetivo.',

  title2: 'Transformação Digital definitiva no seu negócio',

  text2:
    'Alinhados aos seus propósitos, a SIS investe em novas tecnologias que permitem a transformação digital definitiva do seu negócio, disponibilizando aplicações rápidas, tecnologicamente atualizadas, seguras e confiáveis, com completa integração e conectividade. Contamos com profissionais que possuem expertise no desenvolvimento de aplicações no code, por meio da plataforma PEGA, que possibilita a integração através de API´s e está alinhada às principais metodologias ágeis do desenvolvimento.',
}
