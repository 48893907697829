export default {
  banner: ['Agile Coaching', 'em metodologias e frameworks ágeis'],

  title: 'Inovação, transformação e melhoria contínua no seu negócio',

  text1:
    'Fornecemos orientação diária para gerentes e equipes, garantindo que as práticas ágeis sejam aplicadas em toda a empresa. Esse processo auxilia sua organização na Transformação Digital e Organizacional e promove a catalização de uma cultura de melhoria contínua.',

  text2:
    'A equipe de Agile Coaching da SIS atua com as principais metodologias e frameworks ágeis, ajudando as organizações a avaliar ferramentas e implementar técnicas avançadas, com o acompanhamento completo para que se atinja a chamada “maturidade ágil”.',

  text3:
    'São oferecidos também treinamentos para Agile Coaches, Scrum Masters, Gerentes de Projeto, Gerentes de Negócio e equipes de desenvolvimento, além de grupo de mentores para atender todas as suas necessidades de negócio.',
}
