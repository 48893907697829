export default {
  text1: 'Name',
  text2: 'Last Name',
  text3: 'Enter your email',
  text4: 'Phone number',
  text5: ['Have you been recommended for this position?', 'No', 'Yes'],
  text6: 'Name of the person who recommended you',
  text7: 'Corporate email of whom recommended you',
  text8: ['Do you work at SIS?', 'No', 'Yes'],
  text9: 'Please, enter your corporate email',
  text10: 'Upload your Resume',
  text11: 'Send my application',

  talentOption: 'Type of job opportunity',
}
