export default {
  banner: ['Soluções de', 'CRM'],

  text1:
    'A SIS Innov & Tech atua em projetos de CRM, utilizando o ecossistema Salesforce, visando atender as necessidades de vendas, marketing e atendimento ao cliente.',

  text2: [
    'A AMPLA EXPERIÊNCIA EM CONSULTORIA É APLICADA PARA AJUDAR EMPRESAS DE SERVIÇOS, ',
    'VAREJO, TI, MANUFATURA, FINANÇAS E BANCOS, SAÚDE E OUTROS SETORES A SE BENEFICIAREM DO USO DO SALESFORCE.',
  ],

  text3:
    'Se a sua empresa necessita implementar soluções Salesforce ou precisa melhorar processos existentes, a SIS Innov & Tech fornece um portfólio de serviços abrangente, que inclui:',

  list1: [
    [
      '• Definição dos objetivos de negócios onde o ecossistema Salesforce pode apoiar:',
      'A SIS realiza o assessment de processos para mapear os requisitos de negócios, identificando e apresentando as funcionalidades Salesforce que serão necessárias para alcançar os objetivos buscados. Aplicamos as modernas e recomendadas metodologias para obter efetividade nos projetos a serem implementados.',
    ],

    [
      '• Recomendação das nuvens ou ferramentas Salesforce mais adequadas:',
      'As suas necessidades são analisadas pelo time da SIS e sugestões de soluções ideais para o seu negócio são feitas. Escolher as nuvens e ferramentas certas significa que você tem seus desafios de negócios abordados, sem pagar por recursos desnecessários.',
    ],

    [
      '• Projetos que envolvam',
      'processos de vendas, atendimento ao cliente e marketing utilizando as nuvens Salesforce Sales, Service e Marketing Clouds: A SIS automatiza fluxos de trabalho de vendas e atendimento ao cliente, criando jornadas personalizadas de clientes, campanhas publicitárias direcionadas e fazendo muito mais para ajudar os clientes a atingir sucesso.',
    ],
  ],

  list2: [
    [
      '• Integrações com sistemas legados:',
      'Por meio de conectores ou outros middlewares, como Mulesoft, nossos consultores integrarão os sistemas legados, como ERPs com as nuvens Salesforce, criando maior fluxo de dados e processos entre diferentes sistemas.',
    ],

    [
      '• Construção de uma estratégia de adoção de usuários:',
      'Para ajudar seus clientes a dominarem o novo sistema, a SIS cria uma estratégia de adoção do usuário, que inclui treinamento, suporte, gestão de mudança, garantia de qualidade e outros processos de governança que possam ser aplicados.',
    ],
  ],

  text4: ['Produtos Salesforce', 'com os quais trabalhamos:'],

  card: {
    item1: '• Sales Cloud',
    item2: '• Service Cloud',
    item3: '• Marketing Cloud',
    item4: '• Community Cloud',
    item5: '• Financial Services',
    item6: '• Health Cloud',
    item7: '• Salesforce CPQ',
    item8: '• Analytics',
  },

  metaDescription:
    'Implantação de CRM utilizando o ecossistema Salesforce. Automatize seus processos com nossa equipe e ajude a sua área de vendas, marketing e atendimento.',
}
