export default {
  banner: ['Sustentação', 'de Sistemas'],

  title: 'Movidos por tecnologia!',

  content: [
    'A sustentação de sistemas consiste na gestão corretiva, adaptiva e evolutiva dos sistemas de tecnologia utilizados diariamente em uma empresa.',

    'Este serviço é extremamente útil e necessário, a fim de evitar os prejuízos que erros, panes ou bugs possam causar em seu negócio.',

    'O time da SIS é composto por profissionais capazes de compreender rapidamente as particularidades dos sistemas utilizados em sua empresa, explorar todas as funcionalidades e atender prontamente aos chamados abertos.',

    'A governança é muito presente, pois acompanha detalhadamente os chamados, qualificando-os por nível de severidade, onde faz a distribuição do atendimento para as equipes, altamente preparadas, tanto em relação aos aspectos técnicos, quanto aos aspectos do negócio dos clientes.',

    'Para que as expectativas dos clientes sejam atendidas, o trabalho é realizado de acordo com o nível de serviço (SLA), cujo objetivo é atender os chamados dos clientes, sejam dúvidas ou incidentes, solucionando-os dentro de um tempo previamente definido, conforme o nível de severidade dos chamados.',

    'Visando plena transparência na execução das atividades de sustentação, além do SLA, é oferecido também o acompanhamento dos processos e são emitidos relatórios mensais com todos os indicadores dessa operação.',
  ],
}
