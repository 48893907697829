export default {
  text1: 'NOME',
  text2: 'SOBRENOME',
  text3: 'E-MAIL',
  text4: 'TELEFONE',
  text5: ['Você foi indicado para essa vaga?', 'Não', 'Sim'],
  text6: 'NOME DE QUEM TE INDICOU',
  text7: 'E-MAIL CORPORATIVO DE QUEM INDICOU',
  text8: ['Você trabalha na SIS?', 'Não', 'Sim'],
  text9: 'SEU E-MAIL CORPORATIVO',
  text10: 'Faça o upload do seu CV',
  text11: 'ENVIAR CANDIDATURA',

  talentOption: 'Para qual tipo de vaga?',
}
