export default {
  banner: ['Desarrollo', 'Web, Mobile y Mainframe'],

  title: 'Más de 20 años de experiencia',

  text1: '¡Tenemos pasión por tecnología!',

  text2:
    'El objetivo de SIS Consultoría es desarrollar proyectos que contribuyan con el crecimiento de su empresa. Son más de 20 años de experiencia en el mercado, desarrollando sistemas que atiendan las  necesidades de cada cliente.',

  text3: 'Desarrollo Web y Mobile',

  text4:
    'El desarrollo de sistemas Web y Mobile procura implementar soluciones personalizadas para cada cliente. Actuamos en todas las fases de desarrollo del proyecto, desde la concepción hasta la entrega, y con varios lenguajes y frameworks.',

  text5:
    'Nuestros profesionales están aprendiendo constantemente y actualizados con las mejores prácticas del mercado, para proporcionar siempre el mejor producto a nuestros clientes.',

  text6: 'Mainframe',

  text7: 'Total confiabilidad y desempeño',

  text8:
    'SIS consultoría es especialista en alta plataforma y en el desarrollo y sustentación de sistemas de alta disponibilidad. Nuestras soluciones abarcan el procesamiento de grandes volúmenes de datos y permiten la estabilidad, seguridad y confiabilidad de las informaciones.',

  text9:
    'De forma ágil y segura, la vasta experiencia en proyectos mainframe nos asegura que orientaremos al cliente sobre los mejores caminos a seguir para atender las necesidades compartidas. Ya sea para realizar mejoras en los procesos existentes o para desarrollar y reestructurar sistemas completos, cuenta con SIS para los mejores resultados.',
}
