export default {
  metatag: {
    title: 'Recrutamento e seleção de TI - SIS Innov & Tech',
    description:
      'Recrutamento e Seleção especializado em TI. Conheça nosso serviço de hunting em tecnologia, focado em trazer agilidade e qualidade para sua empresa.',
  },
  title1: '',
  titleBold: 'Recrute o profissional ideal de TI para sua empresa!',

  agilityAndEffectiveness: `<b class="font-black">Agilidade e efetividade </b>em seu recrutamento de profissionais de TI.<br><br> A Tech. Hunting é a vertente ideal para você que está com dificuldade para encontrar o profissional de tecnologia certo para as suas demandas.`,

  mainBarriersEncountered: 'As principais barreiras encontradas são:',

  mainBarriersEncounteredExtra:
    'Realizamos um processo seletivo personalizado e ágil, utilizando as melhores práticas e ferramentas de mercado. Sabemos que, atualmente, a tecnologia faz parte da gestão estratégica de pessoas e a contratação de profissionais dessa área não é algo tão simples.',

  list: [
    '• Demanda maior que oferta',
    '• A mutabilidade da área de TI.',
    '• Competitividade na remuneração',
    '• Tripé: perfil da vaga, remuneração e experiência comprovada',
    '• Habilidades técnicas e comportamentais',
  ],

  title2: 'Recrutamento humanizado',
  text3:
    'A Tech. Hunting utiliza a experiência de mais de 20 anos no ramo de TI para facilitar todo o processo de atração e seleção de candidatos para a sua empresa. Por meio de um serviço humanizado, feito de pessoas para pessoas, contamos com um time altamente qualificado em avaliar as habilidades comportamentais e técnicas, identificando, assim, o perfil ideal para a vaga que a sua empresa procura.',
  text4: '',
  button1: 'Fale com Nossos Especialistas',

  title3: 'Precisão e rapidez nos processos seletivos',
  text5: `Disponibilizamos uma equipe especializada para identificar talentos que são compatíveis com o que a sua empresa necessita.`,
  text6:
    ' Por meio de metodologias ágeis, todos os processos são aplicados e desenvolvidos, de forma personalizada, para atender com assertividade a solicitação feita por sua empresa.',

  title4: 'Como acelerar sua jornada',
  carousel: [
    {
      title: 'Alinhamento',
      text: 'Por meio de um bate papo franco e direcionado, queremos entender as suas necessidades específicas, assim como a demanda que precisa ser suprida. Trabalhamos em parceria na construção dos perfis e no mapeamento de mercado para buscar os profissionais mais aderentes às posições em aberto.',
    },
    {
      title: 'Personalização',
      text: 'Nossa equipe desenvolve uma estratégia personalizada focando em prazo, mercado e perfil técnico/comportamental, para atender as necessidades específicas de cada posição, disponibilizando o time necessário para execução.',
    },
    {
      title: 'Execução',
      text: 'Aprovada a estratégia, é hora de agir. A equipe de Atração e Seleção da Tech. Hunting põe em prática toda a sua experiência para captar e atrair os melhores talentos com o melhor prazo de entrega.',
    },
    {
      title: 'Evolução',
      text: 'Após a definição do melhor candidato e finalização do processo de seleção e contratação, a empresa está pronta para gerar ainda mais resultados e voar cada vez mais alto.',
    },
  ],
  button2: 'Acelere sua Jornada com Tech. Hunting by SIS',

  title5: 'Quem somos?',
  text7:
    'O nascimento da Tech. Hunting se deu com o entendimento da necessidade de ter um mercado especializado na contratação, principalmente, de profissionais de TI. Após estruturação de uma equipe estratégica, com entregas de sucesso para os clientes da SIS, considerando tempo, conhecimento técnico e criticidade de cada projeto e ou posição, nosso grande diferencial vem sendo um SLA diferenciado. Essas entregas resultaram no acúmulo de bagagem, experiência e segurança para ofertar o serviço de hunting para o mercado com competência técnica, assertividade e agilidade.',
  title6: 'Quem ajudamos?',
  text8:
    'Empresas com demandas de vagas ativas no mercado, principalmente de Tecnologia. Porém se a sua empresa possui outro nicho e precisa dos serviços de atração e seleção, a Tech. Hunting também pode te ajudar!',
  text9: 'Fale com a gente!',
  title7: 'Como atuamos?',
  text10:
    'A Tech. Hunting chegou para suprir as demandas de atração e seleção com exatidão para os seus clientes. A sua forma de trabalho está baseada em dados, ofertando os serviços de hunting e apresentando os diferenciais em indicadores reais.',

  informativeText: '',

  text11: 'Utilize da nossa ',

  textBold11: 'experiência de mais de 20 anos',

  text12: 'em tecnologia para encontrar o profissional ideal e impulsionar seus resultados.',

  button3: 'Contrate mais rápido com Tech. Hunting by SIS',

  title8: 'CONHEÇA MAIS SOBRE TECH. HUNTING',

  titleRD: 'e Receba o Contato de Nossos Especialistas',
  titleRDBold: 'Preencha o Formulário',

  form: {
    labelHowManyNeed: 'Preciso de :*',
    howManyNeedList: [
      { id: 1, text: '01 a 10 profissionais' },
      { id: 2, text: '10 a 30 profissionais' },
      { id: 3, text: '30 a 50 profissionais' },
      { id: 4, text: '50 ou mais profissionais' },
    ],
  },

  VacanciesWeServe: {
    title: 'Vagas que atuamos',
    carousel: [
      {
        title: 'Tecnologia',
        subtitle: 'Desenvolvimento, Qualidade de Software e Dados,',
        text: 'Consultores, Desenvolvedores, Programadores, Analistas, Arquitetos, Engenheiros e Gerente de Projeto.',
      },
      {
        title: 'Corporativo e Administrativo',
        subtitle: 'Recepção, Financeiro, Jurídico, RH, Compras e Comercial',
        text: 'Auxiliares, Assistentes, Analistas, Compradores, Vendedores, Supervisores, Gerentes e Executivos.',
      },
      {
        title: 'Marketing',
        subtitle: '',
        text: 'Auxiliares, Assistentes, Analistas, Copy, Designer, Social Media e Dados.',
      },
      {
        title: 'Agilidade',
        subtitle: '',
        text: 'PO, PMO, UX/UI, Scrum Master e Agíle Master/Coach.',
      },
      {
        title: 'Infra e Suporte',
        subtitle: '',
        text: 'Auxiliares, Assistentes, Analistas, Supervisores, Coordenadores e Gerentes.',
      },
    ],
  },
}
