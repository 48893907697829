export default {
  metatag: {
    title: 'IT recruitment and selection - SIS Innov & Tech',
    description:
      'IT Recruitment and Selection. Learn more about our tech hunting service, which focuses on providing agility and quality to your company.',
  },
  title1: '',
  titleBold: 'Tech. Hunting Attraction and Selection by SIS',

  agilityAndEffectiveness: `<b class="font-black">Agility and quality </b> in attracting and selecting professionals for your company.<br><br> Bringing together the best candidates and the client companies, we perform a customized and agile selection process, using the best market practices and tools.`,

  mainBarriersEncountered: 'The main barriers encountered are:',

  mainBarriersEncounteredExtra:
    'We know that, nowadays, technology is part of strategic people management, and hiring professionals in this area isn’t very easy.',

  list: [
    '• Demand greater than the supply',
    '• The mutability of the IT area',
    '• Remuneration competitiveness',
    '• Three prongs: job profile, remuneration and proven experience',
    '• Technical and behavioral skills',
  ],

  title2: 'Humanized Recruitment',
  text3:
    'Tech. Hunting uses the experience of almost 20 years in the IT business to facilitate the entire process of attracting and selecting candidates for your company. Through a humanized service, made by people for people, we count on a highly qualified team to evaluate behavioral and technical skills, thus identifying the ideal profile for the position your company is searching.',
  text4:
    '"We perform the head hunting work in a humanized manner. We deliver only qualified and technically approved candidates, because we have internal experts for the most diverse IT profiles who support us during all technical interviews." Cintia Santos - Attraction and Selection Manager',
  button1: 'Click and talk to our experts',

  title3: 'Precision and speed are the key to business',
  text5: `Tech. Hunting values agility in its processes, without losing the quality of its services, because it understands that time is a precious commodity and the urgency of the demands requires speed. Therefore, we make our expert team available to identify talents compatible with what your company needs.`,
  text6:
    'Through agile methodologies, all processes are applied and developed, in a customized manner, to assertively meet the request made by your company.',

  title4: 'Speeding up your journey',
  carousel: [
    {
      title: 'Exchange',
      text: 'Through a frank and directed conversation, we want to understand your specific needs, as well as the demands that need to be met. Thus, we work together to build profiles and map the market in order to find the most suitable professionals for the open positions.',
    },
    {
      title: 'Customization',
      text: 'Our team develops a customized strategy focusing on deadline, market, and a technical/behavioral profile, to meet the specific needs of each position, making the necessary team available for the execution.',
    },
    {
      title: 'Execution',
      text: 'Once the strategy is approved, its time to act. The Tech. Hunting Attraction and Selection team puts all its experience into practice to capture and attract the best talents with the best delivery time.',
    },
    {
      title: 'Evolution',
      text: 'After defining the best candidate and finalizing the selection and hiring process, the company is ready to generate even more results and soar even higher.',
    },
  ],
  button2: 'Click and Speed up your journey with Tech. Hunting by SIS',

  title5: 'About us',
  text7:
    'Tech. Hunting was born from the understanding that there was a need to have a market specialized in hiring, especially IT professionals. After structuring a strategic team, with successful deliveries for SIS clients, considering time, technical knowledge and the criticality of each project and/or position, our major differential has been a unique SLA. These deliveries resulted in the accumulation of experience and security to offer head hunting services to the market, with technical competence, assertiveness and agility.',
  title6: 'Who we help',
  text8:
    'Companies with active demands for job openings in the market, mainly in Technology. However, if your company has another niche and requires the attraction and selection services, Tech. Hunting can also help you!',
  text9: 'Talk to us!',
  title7: 'How we operate',
  text10:
    'Tech. Hunting has come to supply the demands of attraction and selection with accuracy for its clients. Its work method is based on data, offering head hunting services and presenting the unique features in real indicators.',

  informativeText: '*for up to 5 simultaneous vacancies',

  text11: 'We have a bold action schedule, in which we deliver to the client,',

  textBold11: '3 candidates approved in 5 working days*,',

  text12: 'which generates an agile delivery SLA.',

  button3: 'Click and hire faster with Tech. Hunting by SIS',

  titleRD: 'and Receive Contact from Our Experts',
  titleRDBold: 'Fill the form',

  metaDescription:
    'Recruitment and Selection specialized in IT. Discover our technology hunting service, focused on bringing agility and quality to your company.',

  form: {
    labelHowManyNeed: 'I need:*',
    howManyNeedList: [
      { id: 1, text: '01 to 10 professionals' },
      { id: 2, text: '10 to 30 professionals' },
      { id: 3, text: '30 to 50 professionals' },
      { id: 4, text: '50 or more' },
    ],
  },

  VacanciesWeServe: {
    title: 'Vagas que atuamos',
    carousel: [
      {
        title: 'Tecnologia',
        subtitle: 'Desenvolvimento, Qualidade de Software e Dados',
        text: 'Consultores, Desenvolvedores, Programadores, Analistas, Arquitetos, Engenheiros e Gerente de Projeto.',
      },
      {
        title: 'Corporativo e Administrativo',
        subtitle: 'Recepção, Financeiro, Jurídico, RH, Compras e Comercial',
        text: 'Auxiliares, Assistentes, Analistas, Compradores, Vendedores, Supervisores, Gerentes e Executivos.',
      },
      {
        title: 'Marketing',
        subtitle: '',
        text: 'Auxiliares, Assistentes, Analistas, Copy, Designer, Social Media e Dados.',
      },
      {
        title: 'Agilidade',
        subtitle: '',
        text: 'PO, PMO, UX/UI, Scrum Master e Agíle Master/Coach.',
      },
      {
        title: 'Infra e Suporte',
        subtitle: '',
        text: 'Auxiliares, Assistentes, Analistas, Supervisores, Coordenadores e Gerentes.',
      },
    ],
  },
}
