export default {
  banner: ['Soluciones de', 'CRM'],

  text1:
    'SIS Consultoría actúa en proyectos de CRM, utilizando el ecosistema Salesforce, con el propósito de atender las necesidades de ventas, marketing y atención al cliente.',

  text2: [
    'Aplicamos una amplia experiencia en consultoría para ayudar empresas de servicios, ',
    'comercio minorista, TI, manufactura, finanzas y bancos, salud y otros sectores, a beneficiarse con el uso del Salesforce.',
  ],

  text3:
    'Si su empresa necesita implementar soluciones Salesforce o necesita mejorar procesos existentes, SIS Consultoría suministra un portfolio de servicios amplio, que incluye:',

  list1: [
    [
      '• Definición de los objetivos de negocio donde el ecosistema Salesforce puede ayudar:',
      'SIS realiza la evaluación de procesos para mapear los requisitos de negocios, identificando y presentando las funcionalidades Salesforce que serán necesarias para alcanzar los objetivos esperados. Aplicamos las tecnologías más recomendadas y modernas  para lograr efectividad nos proyectos que implementamos.',
    ],

    [
      '•	Recomendación de las nubes o herramientas Salesforce más adecuadas:',
      'El equipo de SIS analiza sus necesidades y sugiere las soluciones ideales para su negocio. Escoger las nubes y herramientas adecuadas significa abordar sus desafíos de negocio, sin tener que pagar recursos innecesarios.',
    ],

    [
      '•	Proyectos que involucren procesos de ventas, atención al cliente y marketing, utilizando las nubes Salesforce Sales, Service y Marketing:',
      'SIS automatiza flujos de trabajo de ventas y atención al cliente, creando jornadas personalizadas de clientes, campañas publicitarias direccionadas y haciendo mucho más para ayudar a los clientes a lograr el éxito.',
    ],
  ],

  list2: [
    [
      '•	Integraciones con sistemas heredados:',
      'Por medio de conectores u otros middlewares, como Mulesoft, nuestros consultores integrarán los sistemas heredados, como ERPS con las nubes Salesforce, creando mayor flujo de datos y procesos entre diferentes sistemas.',
    ],

    [
      '•	Elaboración de una estrategia de adopción de usuarios:',
      'Para ayudar a sus clientes a dominar el nuevo sistema, SIS crea una estrategia de adopción del usuario, que incluye entrenamiento, apoyo, gestión de cambio, garantía de calidad y otros procesos de gobernanza que puedan ser aplicados.',
    ],
  ],

  text4: ['Produtos Salesforce', 'con los cuales trabajamos:'],

  card: {
    item1: '• Sales Cloud',
    item2: '• Service Cloud',
    item3: '• Marketing Cloud',
    item4: '• Community Cloud',
    item5: '• Financial Services',
    item6: '• Health Cloud',
    item7: '• Salesforce CPQ',
    item8: '• Analytics',
  },

  metaDescription:
    'Implementación de CRM utilizando el ecosistema de Salesforce. Automatiza tus procesos con nuestro equipo y ayuda a tu área de ventas, marketing y servicio.',
}
