export default {
  banner: ['Low Code', 'Solutions'],

  title1: 'System development in an agile and simple manner',

  text1:
    'The digital transformation has brought technologies and trends that facilitate and improve IT management. As a company that follows the movements and transformations of the market, SIS offers its clients the most accurate and effective solutions for each objective.',

  title2: 'Definitive Digital Transformation in your business',

  text2:
    'Aligned with your purpose, we invest in new technologies that allow the definitive digital transformation of your business, providing fast, technologically updated, secure and reliable applications, with full integration and connectivity. We rely on professionals who master the development of low code applications through the PEGA platform, which allows full integration through APIs and is in line with the main agile methodologies of the development.',
}
