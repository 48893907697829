import talentBank from './talentBank'
import thanks from './thanks'
import vacancy from './vacancy'
import pageVacancy from './pageVacancy'
import form from './form'

export default {
  beSis: {
    talentBank: {
      ...talentBank,
    },

    thanks: {
      ...thanks,
    },

    vacancy: {
      ...vacancy,
    },

    form: {
      ...form,
    },

    pageVacancy: {
      ...pageVacancy,
    },
  },
}
