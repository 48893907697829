export default {
  banner: ['Design', 'Thinking'],

  title1: 'El ser humano en el centro',

  text1:
    'En SIS, nos importamos con la valorización del ser humano. En las metodologías de desarrollo adoptadas no es diferente. El Design Thinking es un proceso no lineal e interactivo que nuestros equipos usan para entender a los usuarios, desafiar suposiciones, redefinir problemas y crear soluciones innovadoras para prototipos y pruebas.',

  title2: 'Empatizar, Definir, Idealizar, Prototipar y Probar',

  text2: 'La clave de la innovación',

  text3:
    'La meta de SIS con los proyectos de Design Thinking es promover la innovación, canalizando esfuerzos a sus necesidades, comprendiendo su mercado de performance y sus herramientas para, así, diseminar su propuesta de valor en línea con los objetivos pertinentes. Actuamos promoviendo:',

  list: {
    item1: '✔ Solución de los dilemas y desafíos;',
    item2: '✔ Modelaje lógico de los negocios;',
    item3: '✔ Propósito en línea con la innovación y ascensión de su negocio; ',
    item4: '✔ Desarrollo de equipos multidisciplinarios e integrados; ',
    item5: '✔ Desarrollo de productos y servicios innovadores y dedicados a las necesidades de su consumidor.',
  },

  text4: 'Consultoría especializada',

  text5:
    'El liderazgo dedicado de SIS permite que los gestores conduzcan de la mejor manera los puntos de sus organizaciones. El conocimiento técnico de consultoría especializada ayuda a crear en conjunto beneficios para su negocio, afirmando la administración ágil y eficiente que el Design Thinking ofrece.',
}
