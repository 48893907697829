export default {
  contentImageSideAndBottom: {
    top: {
      title: 'FROM PEOPLE TO PEOPLE!',
      content:
        'We know that people are our greatest asset, and for this reason we care for, welcome, train, and develop our team, thus ensuring deliveries in line with expectations and that generate real value for our clients and for us.',
    },

    bottom: {
      title: 'WHERE WE OPERATE',
      content:
        'Our headquarters are located in Alphaville, but we have specialists in several places in Brazil and around the world. Therefore, we are able to serve clients in different locations, and companies from all sectors and segments.',
      button: 'Come and join SIS',
    },

    bottomGptw: {
      button: 'Read More',
    },
  },
}
