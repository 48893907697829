export default {
  shared: {
    validations: {
      requiredField: 'El campo {fieldName} es obligatorio.',
      minAndMaxChars: '"El campo debe tener un mínimo de {min} y un máximo de {max} caracteres.',
      maxChars: 'El campo debe tener un máximo de {max} caracteres.',
      emailInvalid: 'Correo electrónico no válido.',
      urlInvalid: 'URL no válida.',
    },
  },
}
