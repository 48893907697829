export default {
  banner: ['Datos', '& Inteligencia Aplicada'],

  title: '¡Datos impulsando la inteligencia de su negocio!',

  text1: 'Soluciones de BI, Big Data y Analytics',

  text2:
    'Los datos de sus empresas son capaces de guiarlos a las decisiones más asertivas en el contexto de su negocio. Por eso, nuestras soluciones de recolección, distribución y análisis de datos estructurados y no estructurados mejoran sus procesos de trabajo y proporcionan insights valiosos sobre las tendencias del mercado. El objetivo de SIS es facilitar el descubrimiento de oportunidades que están más allá de lo que el cerebro humano logra procesar de forma orgánica.',

  text3: '¡Cuente con SIS para impulsar su negocio y promover la transformación digital!',

  text4:
    'Cubrimos las principales tecnologías de ingesta, integración y procesamiento de datos y ofrecemos las mejores soluciones de:',

  list1: {
    item1: '✔ Big Data',
    item2: '✔ Inteligência Artificial (AI)',
    item3: '✔ Machine Learning',
    item4: '✔ Deep Learning',
    item5: '✔ Business Intelligence',
  },

  list2: {
    item1: '✔ Data Warehouse',
    item2: '✔ Analytics',
    item3: '✔ Data Lake',
    item4: '✔ Data Mart',
    item5: '✔ Data Science',
  },

  list3: {
    item1: '✔ Data Governance',
    item2: '✔ MDM',
    item3: '✔ Data Quality & MDM',
  },

  text5:
    'La Inteligencia Artificial (Machine Learning & Deep Learning) es uno de los pilares de la Transformación Digital, esencial para una organización innovadora, eficiente y sostenible. Las empresas alrededor del mundo están buscando una forma de identificar dónde y cómo generar valor de negocios por medio de la Inteligencia Artificial. Esto forma parte del proceso de Transformación Digital, que permite la automatización de los procesos y la mejoría de las decisiones basadas en datos.',

  text6:
    'En SIS, tenemos vasta experiencia en Machine Learning y Deep Learning, técnicas que proporcionan considerables beneficios a su negocio. Las soluciones propuestas abarcan modelos descriptivos, predictivos y prescriptivos.',
}
