export default {
  banner: ['ERP', 'Solutions'],

  title: 'Using data to leverage business and promote digital transformation',

  text1:
    'Our integrated business management system optimizes your workflow and allows access to information in real time, integrating and unifying data and processes. We work with the modernization of ERP systems, which improves client support and allows a 360-degree view, in addition to qualifying the company for Digital Transformation.',

  text2:
    'Through data integration, SIS facilitates the use of business analytics, artificial intelligence, machine learning, and big data, putting your company at the top with full security and commitment. Aiming at full transparency in the execution of support activities, in addition to the SLA, the monitoring of processes is also offered and monthly reports are issued with all the indicators of this operation.',
}
