export default {
  appliedIntelligence: {
    banner: ['Dados e Inteligência', 'Aplicada'],

    data: {
      title: 'Dados impulsionando a inteligência do seu negócio!',
      text1:
        "<span class='font-bold'>Soluções de BI, Big Data e Analytics</span> <br><br>Os dados das suas empresas são capazes de te guiar para as decisões mais assertivas no contexto do seu negócio. Por isso, nossas soluções de coleta, distribuição e análise de dados estruturados e não estruturados melhoram seus processos de trabalho e te auxiliam para adquirir insights valiosos sobre as tendências do mercado. O objetivo da SIS é facilitar a descoberta de oportunidades que estão além do que o cérebro humano consegue processar de maneira orgânica.",
    },

    leverage: {
      title: 'Conte com a SIS para alavancar seu negócio e promover a transformação digital!',
      button: 'Quero alavancar meu negócio',
    },

    cover: {
      title:
        'Cobrimos as principais tecnologias de gestão, integração e tratamento de dados e oferecemos as melhores soluções de:',
      list1: [
        'Big Data',
        'Inteligência Artificial (AI)',
        'Machine Learning',
        'Deep Learning',
        'Business Intelligence',
        'Data Warehouse',
        'Analytics',
      ],
      list2: ['Data Lake', 'Data Mart', 'Data Science', 'Data Governance', 'MDM', 'Data Quality & MDM'],
    },

    solutions: {
      title: 'Soluções que abrangem modelos descritivos, preditivos e prescritivos.',
      text1:
        'A Inteligência Artificial (Machine Learning & Deep Learning) é um dos pilares da Transformação Digital, essencial para uma organização inovadora, eficiente e sustentável. As empresas ao redor do mundo vêm buscando uma forma de identificar onde e como gerar valor de negócios por meio da Inteligência Artificial. Isso faz parte do processo de Transformação Digital, que permite a automatização dos processos e o aprimoramento das decisões pautadas em dados.',
    },

    impulse: {
      title:
        'Possuímos vasta experiência em Machine Learning e Deep Learning, técnicas que proporcionam grandes benefícios em potencial para o seu negócio.',
      button: 'Impulsione o seu negócio com IA!',
    },
  },
}
