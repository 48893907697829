export default {
  shared: {
    validations: {
      requiredField: 'The field {fieldName} is required',
      minAndMaxChars: 'The field must have a minimum of {min} and a maximum of {max} characters.',
      maxChars: 'The field must have a maximum of {max} characters.',
      emailInvalid: 'Invalid email address.',
      urlInvalid: 'Invalid URL.',
    },
  },
}
