import banners from './banners'
import weAreSis from './weAreSis'
import cards from './cards'
import depositions from './depositions'
import performance from './performance'
import people from './people'
import partOfTeam from './partOfTeam'
import talkToSis from './talkToSis'

export default {
  home: {
    ...banners,
    ...weAreSis,
    ...cards,
    ...depositions,
    ...performance,
    ...people,
    ...partOfTeam,
    ...talkToSis,
    metatag: {
      title: 'SIS Innov & Tech - The best strategy for your company',
      description:
        'SIS Innov & Tech is a strategic Innovation and Digital Transformation consulting service that provides the latest technology for business, with high performance and commitment.',
    },
  },
}
