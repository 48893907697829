export default {
  servicesProducts: {
    banner: '',
    clietsTitle: 'Our Main Customers',
    partnersTitle: 'Partners and Technologies',
    conectPeople: {
      title: '',
      text: '',
      footer: '',
    },
  },
}
