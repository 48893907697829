export default {
  banner: ['Programa', 'de Formação'],

  text1:
    'Este é um programa criado pela SIS para oferecer conhecimento teórico e prático aos estagiários, trainees, jovens aprendizes e profissionais em transição de carreira, tornando-os mais competitivos e capacitados a atender às necessidades da SIS e de seus clientes, além de mais preparados para atuarem nos projetos dentro da empresa.',

  text2:
    'O objetivo é contribuir para que o estagiário, trainee ou jovem aprendiz passe a acreditar no seu potencial e desenvolva consciência sobre temas importantes da vida profissional, por meio de conceitos e da experiência on the job, associada à abordagem prática, proporcionada através de dinâmicas e/ou gamificação.',

  text3: 'A quem se aplica?',

  cards: {
    item1: {
      title: 'ESTÁGIO',
      text: 'Chegou a hora de colocar em prática todo o aprendizado adquirido com seus estudos, por meio do PROGRAMA DE FORMAÇÃO SIS. Além de adquirir conhecimentos sobre o mundo empresarial, você participará de projetos, on the job, sob orientação especializada de nossos melhores profissionais para poder expandir seus horizontes e investir no seu crescimento pessoal e profissional.',
      buttonLink: '/trabalhe-conosco/estagio',
      buttonContent: 'VENHA SER SIS',
    },
    item2: {
      title: 'TRAINEE',
      text: 'Faça os primeiros passos em sua carreira valerem a pena através do PROGRAMA DE FORMAÇÃO SIS. Atue em projetos importantes, obtenha orientação de líderes experientes, teste suas ideias e cresça com a gente.',
      buttonLink: '/trabalhe-conosco/trainee',
      buttonContent: 'VENHA SER SIS',
    },
    item3: {
      title: 'JOVEM APRENDIZ',
      text: 'Inicie sua vida profissional numa empresa que investe na contratação, treinamento e desenvolvimento de jovens através do PROGRAMA DE FORMAÇÃO SIS.',
      buttonLink: '/trabalhe-conosco/jovem-aprendiz',
      buttonContent: 'VENHA SER SIS',
    },
  },

  text4: ['Premissas fundamentais', 'do Programa de Formação'],

  text5: '✔ Oferecer profissionais qualificados para nossos clientes entendendo o momento atual do mercado.',

  text6: '✔ Investir na selecão, contratação, formação e retenção de estagiários, jovens aprendizes e trainees.',

  text7: '✔ Desenvolver treinamentos aderentes às necessidades de mercado para sermos mais competitivos.',

  icons: {
    item1: {
      title: 'PÚBLICO ALVO',
      text: ['Estagiários | Trainees | Jovens Aprendizes'],
      icon: 'img2.webp',
    },
    item2: {
      title: 'MÓDULOS',
      text: ['Técnico | Comportamental | Práticas Ágeis e Liderança | Negócio'],
      icon: 'img3.webp',
    },
    item3: {
      title: 'PLANO DE DESENVOLVIMENTO',
      text: [
        'até 02 anos com possível efetivação a partir do 7º mês Feedback Trimestral Reajuste por desempenho a cada 06 meses',
        'Duração:',
      ],
      icon: 'img6.webp',
    },
  },

  text8: 'Depoimentos',

  text9:
    'O Programa de Formação é de grande valor na SIS e o que nos deixa orgulhosos e certos de que estamos no caminho certo, são os depoimentos que seguem:',

  text10: 'Parceiros:',
}
