export default {
  banner: ['Quality', 'Assurance'],

  title: 'Transformación digital con garantía de calidad',

  text1:
    'En SIS Consultoría, nos preocupamos con la calidad y eficiencia del producto o servicio entregado a cada cliente. Después de todo, esos son criterios esenciales e indispensables. Asumimos el compromiso de asegurar la entrega del servicio a los usuarios finales con la máxima calidad, garantizando la excelencia esperada. Ud. encuentra en SIS la reducción de los costos de operación, optimización de la rutina de trabajo y la anticipación de problemas, actuando con:',

  list: {
    item1: '✔ Consultoría en calidad',
    item2: '✔ Automación de prueba y RPA',
    item3: '✔ Fábrica de pruebas',
    item4: '✔ Pruebas funcionales',
    item5: '✔ Pruebas no funcionales (Performance, Usabilidad, Portabilidad, y otras)',
    item6: '✔ Pruebas mobile, web, desktop y de servicios',
    item7: '✔ Pruebas de seguridad (pentest, dast, sast, iast)',
    item8: '✔ Y auditoría de código',
  },

  text2:
    'Así, el Quality Assurance representa la mejor estrategia para evitar problemas, creando mecanismos para identificarlos y tratarlos con eficiencia.',
}
