export default {
  cards: {
    title: ['Nuestras', 'Soluciones'],
    card1: {
      title: ['Estrategia', 'de negocios/tecnología'],
      description:
        'Somos altamente cualificados y trabajamos en línea con las principales prácticas y tendencias del mercado. Invertimos constantemente en el cuerpo estratégico, gerencial y en los equipos multidisciplinarios, contemplando aspectos de negocio, técnicos y conductuales.',
    },

    card2: {
      title: ['Transformación', 'Digital'],
      description:
        'Actuamos en diversos proyectos de desarrollo de nuevas funcionalidades, para diferentes activos digitales y modalidades de servicios, como también en el desarrollo ágil, web y mobile.',
    },

    card3: {
      title: ['Sustentación', 'de Aplicaciones'],
      description:
        'Tenemos un equipo completo de desarrollo, compuesto por Arquitectos Técnicos, Product Owners, Scrum Masters, UX/UI, Desarrolladores e Ingenieros DevOps. Así, posibilitamos el desarrollo de nuevos productos y proyectos de forma ágil.',
    },

    card4: {
      title: ['Squad de', 'Desarrollo'],
      description:
        'Ofrecemos una gestión correctiva, adaptativa y evolutiva de los sistemas de tecnología utilizados diariamente en una empresa.',
    },
  },
}
