export default {
  servicesProducts: {
    banner: 'O que Fazemos',
    clietsTitle: 'Nossos Principais Clientes',
    partnersTitle: 'Parceiros e Tecnologias',
    conectPeople: {
      title: 'Conectamos Pessoas, Processos e Tecnologias',
      text: 'Nós somos uma empresa especializada em inteligência tecnológica que desenvolve a estratégia ideal para entregar as melhores soluções personalizadas para cada desafio dos nossos clientes.',
      footer: 'Para nós, cada cliente é único!',
    },
  },
}
