export default {
  banner: ['Quality', 'Assurance'],

  title: 'Digital transformation with Quality Assurance',

  text1:
    'At SIS Consulting, we are concerned with the quality and efficiency of the product or service delivered to each client. After all, these are essential and indispensable criteria. We are committed to ensuring the delivery of the service to end users with the highest quality, guaranteeing the desired excellence. At SIS, you will find reduced operational costs, optimization of the work routine, and the anticipation of problems, working with:',

  list: {
    item1: '✔ Quality consulting',
    item2: '✔ Test automation and RPA',
    item3: '✔ Test factory',
    item4: '✔ Functional tests',
    item5: '✔ Non-functional tests (Performance, Usability, Portability, among others)',
    item6: '✔ Mobile, web, desktop and service tests',
    item7: '✔ Security testing (pentest, dast, sast, iast)',
    item8: '✔ Code auditing',
  },

  text2:
    'Thus, Quality Assurance represents the best strategy to avoid problems by creating mechanisms to identify and handle them efficiently.',
}
