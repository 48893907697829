export default {
  banner: ['Agile Coaching', 'en Metodologías y Frameworks Ágiles'],

  title: 'Innovación, transformación y mejoría continua de su negocio',

  text1:
    'Suministramos orientación diaria para gerentes y equipos, garantizando que las prácticas ágiles se apliquen en toda la empresa. Este proceso favorece su organización en la Transformación Digital y Organizacional y promueve la intensificación de una cultura de mejoría continua.',

  text2:
    'El equipo de Agile Coaching de SIS actúa con las principales metodologías y frameworks ágiles, ayudando a las organizaciones a evaluar herramientas e implementar técnicas avanzadas, con el seguimiento completo para que se alcance lo que se llama “madurez ágil”.',

  text3:
    'Se ofrecen también entrenamientos para Agile Coaches, Scrum Masters, Gerentes de Proyecto, Gerentes de Negocio y equipos de desarrollo, además de grupo de mentores para atender todas las necesidades de su negocio.',
}
