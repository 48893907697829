export default {
  banners: {
    item1: {
      title1: '#Weare',
      title2: 'SIS',
      description:
        'A warm and stimulating place to boost creativity and celebrate all of our achievements.  A favorable environment directly impacts our quality of life and the services and products that will be delivered to our clients.',
    },
    item2: {
      title1: '#Weare',
      title2: 'Tech',
      description:
        "The technology that shows us the endless possibilities that we have in order to overcome our challenges and provide the best solutions for our clients. Our guidelines are soundness, transparency, trust, and the organization's commitment to all the projects we propose to carry out.",
    },
    item3: {
      title1: '#Weare',
      title2: 'Transformation',
      description:
        'Always in search of evolution, we are determined to change the world. Our employees are constantly learning - through a variety of methods – how to expand and enhance their potential and deliver solutions with purpose.',
    },
  },
}
