export default {
  banner: ['Você na', 'SIS', 'Venha fazer parte do nosso time!'],
  buttonMobile: 'FILTROS',
  filters: {
    item1: 'Modelo de trabalho',
    item2: 'Nível Profissional',
    item3: 'Áreas de interesse',
    item4: 'Tecnologias',
    item5: 'BANCO DE TALENTOS',
  },

  card: {
    text1: 'Benefícios:',
    buttonContent: 'CADASTRE-SE',
  },

  notFound: {
    text1: 'A vaga que você selecionou não está disponível no momento.',
    text2: 'Cadastre-se em nosso banco de talentos para futuras oportunidades!',
    text3: 'VENHA SER SIS',
  },

  buttonContent: 'CARREGAR MAIS',
}
