export default {
  products: {
    banner: 'What we do',
    title1: 'From people, for people ',
    text1: [
      'We are a Technological Intelligence company specializing in Innovation and Digital Transformation. We combine technology and humanization, promoting the best market solutions for companies in all segments.',
      'Our purpose is to bring the top technological advances to your business development, through agile methodologies and tools that simplify processes.',
    ],
    title2: 'Innovation and Digital Transformation:',
    text2:
      'Innovation and Digital Transformation are our specialties. From insights to actions, we provide full service, with security, quality and customization.',
    button1: 'I want a customized solution',
    title3: 'Solutions',
    text3: 'Explore the latest technology to make your company the leader in its field, using our services.',
    button2: 'I want a unique solution',
    title4: 'Our Partners',
    text4:
      'We have partnerships with the most renowned software and programs in the world. Our employees are certified to offer full service, in an assertive and safe manner.',
    title5: 'Talk to SIS',
    text5: 'If you couldn’t find what you were looking for in our services tab, please contact us! ',
    button3: 'Talk to SIS',
    knowMore: 'Learn more about us',

    itemsDigitalTransformation: [
      {
        title: 'Multiplataform Development',
        text: 'The objective of SIS Consulting is to develop projects that contribute to the...',
      },
      {
        title: 'Data & Applied Intelligence',
        text: "Your company's data is capable of guiding you in the most assertive decision...",
      },
      {
        title: 'Quality Assurance',
        text: 'At SIS Consulting, we are concerned with the quality and efficiency of the pr...',
      },
      {
        title: 'Cloud Computing Services',
        text: 'Cloud access has emerged as a way to democratize information and improve the ...',
      },
      {
        title: 'Agile Coaching and Frameworks',
        text: 'We provide daily guidance for managers and teams, ensuring that agile practic...',
      },
      {
        title: 'Design Thinking',
        text: 'At SIS, we value human beings. In the development methodologies we adopt, it ...',
      },
      {
        title: 'UX e UI',
        text: 'The quality of your UX is one of the decisive factors for a user to choose be...',
      },
      {
        title: 'DevOps',
        text: 'The DevOps culture combines practices, tools, and cultural philosophies that ...',
      },
    ],

    itemsSolutions: [
      {
        title: 'Tech. Hunting',
        text: 'Bringing together the best candidates and the client companies, we perform a ...',
      },
      {
        title: 'Strategic Consulting',
        text: 'The best strategy for your company: using information technology in favor of ...',
      },
      {
        title: 'Environment Support',
        text: 'Environment support consists of the corrective, adaptive, and evolutionary ma...',
      },
      {
        title: 'ERP',
        text: 'Our integrated business management system optimizes your workflow and allows ...',
      },
      {
        title: 'CRM',
        text: 'SIS Consulting works with CRM projects, using the Salesforce ecosystem, aimin...',
      },
      {
        title: 'Low Code',
        text: 'The digital transformation has brought technologies and trends that facilitat...',
      },
      {
        title: 'Agile Cells - Squads',
        text: 'Digital Transformation is driving many companies to restructure their organiz...',
      },
    ],

    metatag: {
      title: 'Digital Transformation Consulting - SIS Innov & Tech',
      description:
        'We provide digital transformation and innovation in a personalized manner, with specific strategies to meet the needs of each of our clients.',
    },
  },
}
