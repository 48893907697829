import filters from './filters'

export default {
  blog: {
    title: ['Blog', 'SIS'],
    subtitle: 'Acompanhe as novidades sobre o mundo de tecnologia e soluções SIS',
    button: 'VEJA MAIS POSTS',

    filters: {
      ...filters,
    },
    metatag: {
      title: 'SIS Innov & Tech - Blog',
      description: 'Os melhores conteúdos, feitos por especialistas de tecnologia!',
    },
  },
}
