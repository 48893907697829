export default {
  performance: {
    title: 'Entrega com Alta Performance e Comprometimento',
    descriptionFirst:
      'Cada cliente é único. Ouvimos o seu desafio, entendemos as necessidades, nos aprofundamos na sua realidade e indicamos os produtos e serviços para os melhores resultados.',
    descriptionSecond: 'Empresas que aderem a tecnologia em seus processos estão sempre a frente no mercado!',
    button: 'Leia mais',
    cards: {
      title: 'O que <span class="font-bold">Fazemos</span>',
      first: {
        title: 'Transformação Digital',
        button: 'Veja Mais',
      },
      second: {
        title: 'Serviços',
        button: 'Veja Mais',
      },
    },
  },
}
