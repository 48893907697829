export default {
  howWeDo: {
    title: 'HOW DO WE DO IT?',
    cards: [
      {
        title: 'Each client is unique',
        text: 'Each client is unique We listen to the difficulties, diagnose the needs, and recommend and execute the most appropriate products and services for the best results.',
      },
      {
        title: "We experience our client's reality",
        text: "We experience our client's reality Our team of specialists has the skills to delve into the details of the client's market and meet their needs in a more agile, efficient, and personalized manner.",
      },
      {
        title: 'Proximity',
        text: "We engage with our clients' challenges with confidence and commitment.",
      },
    ],
  },
}
