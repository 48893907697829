import navbar from './navbar'
import footer from './footer'
import home from './home/index'
import weAreSis from './weAreSis/index'
import solutions from './solutions'
import innovation from './innovation'
import career from './career'
import blog from './blog'
import contact from './contact'
import beSis from './beSis'
import lgpd from './lgpd'
import pageNotFound from './pageNotFound'
import products from './products'
import transformation from './transformation'
import successCase from './successCase'
import shared from './shared'
import clientsAndPartners from './clientsAndPartners'
import servicesProducts from './servicesProducts'

export default {
  ...servicesProducts,
  ...navbar,
  ...footer,
  ...home,
  ...weAreSis,
  ...solutions,
  ...innovation,
  ...career,
  ...blog,
  ...contact,
  ...beSis,
  ...lgpd,
  ...pageNotFound,
  ...products,
  ...transformation,
  ...successCase,
  ...shared,
  ...clientsAndPartners,
  codeEthics: {
    metatag: {
      title: 'Código de ética e conduta - SIS Innov & Tech',
      description:
        'No nosso Código de Ética & Conduta, te explicamos como conduzimos todas as atividades, internas e externas, com base neste guia.',
    },
  },
  cookies: {
    metatag: {
      title: 'Política de cookies - SIS Innov & Tech',
      description:
        'Em nossa Política de Cookies, explicamos o que são cookies, como e por que usamos as informações capturadas por eles.',
    },
  },
  privacyPolicy: {
    metatag: {
      title: 'Política de privacidade - SIS Innov & Tech',
      description:
        'Nossa Política de Privacidade é aplicável a todas as informações coletadas em nosso site. Fornecemos informações sobre como coletamos, usamos e tratamos os seus dados pessoais.',
    },
  },
}
