export default {
  banner: ['Work with', 'SIS', 'Join our team!'],
  buttonMobile: 'Search',
  filters: {
    item1: 'Work model',
    item2: 'Professional level',
    item3: 'Areas of Interest',
    item4: 'Technologies',
    item5: 'Talent bank',
  },

  card: {
    text1: 'Benefits:',
    buttonContent: 'Register',
  },

  notFound: {
    text1: 'The job opportunity you selected is not available right now.',
    text2: 'Sign up for our talent bank for future opportunities!',
    text3: 'Register',
  },

  buttonContent: 'READ MORE',
}
