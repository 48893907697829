export default {
  banners: {
    item1: {
      title1: '#Somos',
      title2: 'SIS',
      description:
        'Um lugar acolhedor e estimulante, para impulsionar a criatividade e celebrar todas as nossas conquistas. Um ambiente favorável impacta diretamente em nossa qualidade de vida e nos serviços e produtos que serão entregues aos nossos clientes.',
    },
    item2: {
      title1: '#Somos',
      title2: 'Tecnologia',
      description:
        'A tecnologia que nos mostra as infinitas possibilidades que temos para superar nossos desafios e proporcionar as melhores soluções aos nossos clientes. Nossas diretrizes são a solidez, a transparência, a confiança e o comprometimento da organização com todos os projetos que nos propomos.',
    },
    item3: {
      title1: '#Somos',
      title2: 'Transformação',
      description:
        'Sempre na busca por evolução constante, somos determinados em mudar o mundo. Nossos colaboradores estão em constante aprendizagem, por meio dos mais variados métodos para ampliar e aprimorar seu potencial e entregar soluções com propósitos.',
    },
  },
}
