export default {
  banner: ['SIS', 'Academy'],

  text1:
    '¡Conozca la academia de entrenamiento y desarrollo basada en la manera SIS de vencer! Aquí preparamos nuestros profesionales para estar siempre al frente y para sobresalir en el mercado de trabajo.',

  text2:
    'En la SIS Academy, proporcionamos interacciones semanales en las que discutimos cuestiones como la cultura organizacional, metodologías ágiles, temas conductuales y técnicos y las principales tendencias de la escena corporativa.',

  text3: 'Workshops y Entrenamientos',

  text4:
    'Promovemos conferencias, workshops y meetups con el propósito de ofrecer formación continuada a nuestros profesionales.',

  text5:
    'SIS tiene una colaboración con la plataforma de la Alura para ofrecer entrenamientos a los colaboradores. Son más de 1.200 cursos diferentes. SIS  costea 100 % de la plataforma y ofrece cursos técnicos y conductuales para todo el equipo. Por medio de la plataforma, creamos caminos de desarrollo ágil, técnico y conductual, promoviendo la evolución y el aprendizaje continuo de los profesionales.',

  text6:
    'En SIS, creemos que invertir en certificación y entrenamiento es el mayor diferencial para el éxito y la sostenibilidad corporativa. SIS tiene una Política de Certificación transparente, que subvenciona las certificaciones necesarias para el desarrollo de sus colaboradores en línea con los negocios de la empresa.',
}
