export default {
  banner: ['CRM ', 'Solutions'],

  text1:
    'SIS Consulting works with CRM projects, using the Salesforce ecosystem, aiming at meeting sales, marketing, and customer service needs.',

  text2: [
    'Extensive consulting experience is applied to help service, retail, IT, ',
    'manufacturing, finance and banking, healthcare, and companies in other industries, benefit from using Salesforce.',
  ],

  text3:
    'Whether your company needs to implement Salesforce solutions or needs to improve existing processes, SIS Consulting provides a comprehensive service portfolio, which includes:',

  list1: [
    [
      '• The definition of business goals where the Salesforce ecosystem can support: ',
      'SIS performs process assessment to map business requirements, identifying and presenting the Salesforce features that will be necessary to achieve the intended goals. We apply the modern and recommended methodologies to obtain effectiveness in the projects to be implemented.',
    ],

    [
      '• Recommendation of the most suitable cloud or Salesforce tools:',
      'Your needs are analyzed by the SIS team and suggestions for the ideal solutions for your business are made. Choosing the right clouds and tools means you have your business challenges addressed, without paying for unnecessary resources.',
    ],

    [
      '• Projects involving ',
      'sales, customer service, and marketing processes using Salesforce Sales, Service, and Marketing Clouds: SIS automates sales and customer service workflows, creating personalized client journeys, targeted advertising campaigns, and doing much more to help clients achieve success.',
    ],
  ],

  list2: [
    [
      '• Integrations with legacy systems:',
      'Through connectors or other middleware, such as Mulesoft, our consultants will integrate legacy systems, such as ERPs with Salesforce clouds, creating greater flow of data and processes between different systems.',
    ],

    [
      '• Building a user-adoption strategy:',
      'In order to help your clients master the new system, SIS creates a user-adoption strategy that includes training, support, change management, quality assurance, and other governance processes that can be applied.',
    ],
  ],

  text4: ['Salesforce products', 'we work with:'],

  card: {
    item1: '• Sales Cloud',
    item2: '• Service Cloud',
    item3: '• Marketing Cloud',
    item4: '• Community Cloud',
    item5: '• Financial Services',
    item6: '• Health Cloud',
    item7: '• Salesforce CPQ',
    item8: '• Analytics',
  },

  metaDescription:
    'CRM deployment using the Salesforce ecosystem. Automate your processes with our team and help your sales, marketing and service area.',
}
