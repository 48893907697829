export default {
  banner: 'DevOps',

  title1: 'Leading your operations’ transformation',

  text1:
    'The DevOps culture combines practices, tools, and cultural philosophies that aim to increase the ability of companies to deliver software and services, with greater agility than conventional processes, speeding up the delivery of value to the clients.',

  text2: 'Agile methodologies and tools optimizing your development!',

  button1: 'I want to optimize my business',

  title2: 'We transform your Agile Development processes',

  text3:
    'Through a team experienced in applying infrastructure approaches such as code (IaC), continuous integration, and continuous delivery (CI/CD), SIS offers end-to-end services based on modern methodologies and tools, to always provide clients with the best possible experience.',

  text4:
    'SIS knows the entire agile process of developing software and provides an assessment, which aims at understanding how the clients processes work regarding software development and operation, everything to deliver efficient solutions for your company',

  text5: 'SIS offers powerful DevOps solutions for your business',

  button2: 'Get to know now!',

  title3: 'Read more about DevOps',
}
