export default {
  banner: ['Soluções de', 'ERP'],

  title: 'Usando dados para alavancar negócios e promover transformações digitais',

  text1:
    'Nosso sistema integrado de gestão empresarial otimiza seu fluxo de trabalho e viabiliza o acesso às informações em tempo real, integrando e unificando os dados e processos. Trabalhamos com a modernização dos sistemas ERP, o que melhora o suporte ao cliente e habilita sua visão 360º graus, além de qualificar a empresa para a Transformação Digital.',

  text2:
    'Por meio da integração dos dados, a SIS facilita o uso de business analytics, inteligência artificial, machine learning e big data, colocando a sua empresa no topo com total segurança e comprometimento. Visando plena transparência na execução das atividades de sustentação, além do SLA, é oferecido também o acompanhamento dos processos e são emitidos relatórios mensais com todos os indicadores dessa operação.',
}
