export default {
  banners: {
    item1: {
      title1: '#Somosuna',
      title2: 'SIS',
      description:
        'Un lugar acogedor y estimulante, para impulsar la creatividad y celebrar todas nuestras conquistas.  Un ambiente favorable impacta directamente en nuestra calidad de vida y en los servicios y productos que entregamos a nuestros clientes.',
    },
    item2: {
      title1: '#Somos',
      title2: 'Tecnologia',
      description:
        'La tecnología que nos muestra las infinitas posibilidades que tenemos para superar nuestros desafíos y proporcionar las mejores soluciones a nuestros clientes. Nuestras directrices son la solidez, la transparencia, la confianza y el compromiso de la organización con todos los proyectos que nos hemos propuesto realizar.',
    },
    item3: {
      title1: '#Somos',
      title2: 'Transformación',
      description:
        'Siempre en búsqueda de evolución, estamos determinados a cambiar el mundo. Nuestros colaboradores están aprendiendo constantemente, por medio de los más variados métodos para ampliar y perfeccionar su potencial para entregar soluciones con propósitos.',
    },
  },
}
