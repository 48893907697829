export default {
  banner: ['Dados', 'e Inteligência Aplicada'],

  title: 'Dados impulsionando a inteligência do seu negócio!',

  text1: 'Soluções de BI, Big Data e Analytics',

  text2:
    'Os dados das suas empresas são capazes de te guiar para as decisões mais assertivas no contexto do seu negócio. Por isso, nossas soluções de coleta, distribuição e análise de dados estruturados e não estruturados melhoram seus processos de trabalho e te auxiliam para adquirir insights valiosos sobre as tendências do mercado. O objetivo da SIS é facilitar a descoberta de oportunidades que estão além do que o cérebro humano consegue processar de maneira orgânica.',

  text3: 'Conte com a SIS para alavancar seu negócio e promover a transformação digital!',

  text4:
    'Cobrimos as principais tecnologias de gestão, integração e tratamento de dados e oferecemos as melhores soluções de:',

  list1: {
    item1: '✔ Big Data',
    item2: '✔ Inteligência Artificial (AI)',
    item3: '✔ Machine Learning',
    item4: '✔ Deep Learning',
    item5: '✔ Business Intelligence',
  },

  list2: {
    item1: '✔ Data Warehouse',
    item2: '✔ Analytics',
    item3: '✔ Data Lake',
    item4: '✔ Data Mart',
    item5: '✔ Data Science',
  },

  list3: {
    item1: '✔ Data Governance',
    item2: '✔ MDM',
    item3: '✔ Data Quality & MDM',
  },

  text5:
    'A Inteligência Artificial (Machine Learning & Deep Learning) é um dos pilares da Transformação Digital, essencial para uma organização inovadora, eficiente e sustentável. As empresas ao redor do mundo vêm buscando uma forma de identificar onde e como gerar valor de negócios por meio da Inteligência Artificial. Isso faz parte do processo de Transformação Digital, que permite a automatização dos processos e o aprimoramento das decisões pautadas em dados.',

  text6:
    'Na SIS, possuímos vasta experiência em Machine Learning e Deep Learning, técnicas que proporcionam grandes benefícios em potencial para o seu negócio. As soluções propostas abrangem modelos descritivos, preditivos e prescritivos.',
}
