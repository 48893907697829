export default {
  performance: {
    title: 'Delivery with High Performance and Commitment',
    descriptionFirst:
      'Each client is unique. We listen to their challenges, understand their needs, analyze their reality in-depth, and recommend the products and services for the best results.',
    descriptionSecond:
      'Companies that adhere to technology in their processes always have a competitive edge in the marketplace!',
    button: 'Read more',
    cards: {
      title: 'What <span class="font-bold"> we do</span>',
      first: {
        title: 'Digital Transformation',
        button: 'See more',
      },
      second: {
        title: 'Services',
        button: 'See more',
      },
    },
  },
}
