import hero from './hero'
import howWeAre from './howWeAct'
import contentImageSideAndBottom from './contentImageSideAndBottom'
import howWeDo from './howWeDo'
import clients from './clients'

export default {
  ...hero,
  ...howWeAre,
  ...contentImageSideAndBottom,
  ...howWeDo,
  ...clients,
  metatag: {
    title: 'Quiénes Somos - SIS Innov & Tech',
    description:
      'Desde hace 20 años en el mercado, somos una consultoría estratégica de Innovación y Transformación Digital. Nuestra especialidad es impulsar negocios.',
  },
}
