import blog from './shared/blog'

export default {
  ...blog,

  pageBlogList: {},
  meta: {
    title: 'SIS Innov & Tech - Blog',
    description: '¡Los mejores contenidos, hechos por expertos en tecnología!',
  },
}
