import about from './about/index'
import successCases from './successCases'

export default {
  weAreSis: {
    about: {
      ...about,
    },

    successCases: {
      ...successCases,
    },

    clients: {
      banner: ['Clients', ', Partners and Technologies', 'A successful path together.'],
      title1: 'Some of our clients',
      title2: ['SIS partners', 'and key technologies'],
    },
  },
}
