import banners from './banners'
import weAreSis from './weAreSis'
import cards from './cards'
import depositions from './depositions'
import performance from './performance'
import people from './people'
import partOfTeam from './partOfTeam'
import talkToSis from './talkToSis'

export default {
  home: {
    metatag: {
      title: 'SIS Innov & Tech - A melhor estratégia para a sua empresa',
      description:
        'A SIS Innov & Tech é uma consultoria estratégica de Inovação e Transformação Digital que fornece o que há de mais tecnológico para os negócios, com alta performance e comprometimento.',
    },
    ...banners,
    ...weAreSis,
    ...cards,
    ...depositions,
    ...performance,
    ...people,
    ...partOfTeam,
    ...talkToSis,
  },
}
