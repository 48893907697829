export default {
  banner: ['Environment', 'Support'],

  title: 'Driven by technology!',

  content: [
    'Environment support consists of the corrective, adaptive, and evolutionary management of the technology systems used daily in a company.',

    'This service is extremely useful and necessary in order to avoid the losses that errors, shutdowns, or bugs can cause in your business.',

    'The SIS team is made up of professionals capable of quickly understanding the particularities of the systems used in your company, exploring all features, and promptly responding to open calls.',

    "Governance is quite present, because it tracks the calls in detail, classifying them by level of severity, where it distributes the service to the teams, which are highly prepared, both in relation to technical aspects and to the client's business aspects.",

    "In order to meet the clients' expectations, the work is carried out according to the service level agreement (SLA), whose goal is to respond to the clients' calls, whether they have questions or incidents occur, solving them within a previously defined timeframe, according to the severity level of the calls.",

    'Aiming at full transparency in the execution of support activities, in addition to the SLA, the monitoring of processes is also offered, and monthly reports are issued with all the indicators of this operation.',
  ],
}
