export default {
  banner: ['User Experience and User Interface', '(UX/UI)'],

  title1: 'Do you know how UX affects your company?',

  text1:
    'The quality of your UX is one of the decisive factors for a user to choose between you and your competitor. After all, the level of ambition, the internal processes, and an entire set of tools - required to provide the right experience - shape your organization.',

  text2:
    "UX Design is the catalyst for bringing together users, the technology, and the company, all through the creation of interfaces. Our goal is to deliver design that – with each interaction - makes the user's life better.",

  title2: 'Beyond functional, UI makes everything nice',

  text3: 'Interaction, usability and look: everything you expect!',

  text4:
    'The work of the SIS UI Designers represents everything that’s used in the interaction with the product. They are responsible for creating functional interfaces, which allow users to browse, with the best experience, a platform that goes far beyond its beauty.',

  text5:
    'Our goal is the final delivery of an interface, be it visual or not, that ensures maximum interaction, performance, and usability: we understand the user’s journey, apply and build the best paths towards excellence.',
}
