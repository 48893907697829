export default {
  banner: ['Employer', 'Branding'],

  text1:
    'At SIS, we seek to become a better company on a daily basis, and for this reason, we value people and victories. Believing in the importance of moments of interaction, every month, we take the time to celebrate staff birthdays, and present, to our employees, everything that has happened, such as new clients, projects, training schedule and campaigns.',

  text2: 'Coffee with the CEO',

  text3:
    'We believe in participative management, and therefore we hold the monthly Coffee with the CEO, a moment dedicated to sharing ideas and suggestions for the company.',

  text4:
    "Our employees meet with the entire executive board and are heard in a relaxed and informal conversation. This is a way of valuing everyone's opinion within the organization, which has produced good results.",

  text5: 'GYMPASS',

  text6:
    "Gympass is a benefit that aims to stimulate the practice of exercises and is offered to all employees. Thus, we seek to encourage an improvement in everyone's health and well-being.",

  text7: 'How does it work?',

  text8:
    'SIS pays a monthly fee so that everyone has access to cheaper monthly fees and can enjoy the benefit in a large chain of gyms and studios. In addition, Gympass is a platform that connects employees to partners who provide tips on workouts, meditation, nutritional planning and quality of life, as well as online classes that are always available and can be accessed from anywhere.',

  text9: 'From people to people',

  text10:
    'At SIS, we are concerned with our employees’ welfare. We believe that investing in a solid base, made up of people, makes us go higher and higher. Therefore, our employees can count on motivational actions that range from moments of integration to the care of body and mind.',

  text11: 'Check out a few of our initiatives:',

  cards: {
    item1: {
      title1: 'Systemic Massage Therapy',
      description:
        'With emphasis on the energetic channels of Chinese Medicine, in the settling energetic and emotional points of the spine. This work has the goal of looking at the physical and emotional body in the family systemic aspect and the various ways of relating in life and with life.',
      title2: 'To treat:',
      list1: ['✔ Stress', '✔ Anxiety', '✔ High blood pressure', '✔ Arthritis'],
      list2: ['✔ Low immunity', '✔ Poor circulation', '✔ Energy alignment'],
    },

    item2: {
      title1: 'Quick Massage',
      description:
        'The benefits of quick massages can be felt immediately and only get better with each session. The relief of muscular pain, the relaxation of the neck, back, and arm regions, and the prevention of repetitive strain injuries (RSIs) and work-related musculoskeletal disorders (WMSDs) are some of its advantages.',
      title2: 'Furthermore, it’s proven that it:',
      list1: ['✔ Improves productivity', '✔ Decreases the sick leave rate'],
      list2: [
        '✔ It improves the company-employee relationship',
        '✔ Assists in maintaining the team’s health and well-being',
      ],
    },

    item3: {
      title1: 'Workplace Exercise',
      description:
        'It’s performed a few times a week at the workplace by employees who are interested. Workplace exercise is done through the collective physical activities, and its benefits include:',
      list1: ['✔ Fighting a sedentary lifestyle', '✔ Improved physical conditioning', '✔ Disease prevention'],
      list2: ['✔ Fighting stress and tension', '✔ Reduced mental and physical fatigue', '✔ Improved self-esteem'],
    },
  },

  text12: ['#Weare'],

  text13:
    '2020 was marked by our first participation and the achievement of the Great Place to Work (GPTW) certificate, a recognition given to companies that are work environment benchmarks around the world!',

  text14:
    'Our success is built daily, in partnership with each employee. Through a team of great professionals who are dedicated and committed, we ensure the delivery of the best services and solutions to our clients.',

  buttonContent: 'COME WORK AT SIS',
}
