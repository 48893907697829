export default {
  banner: ['Agile Coaching', 'in Agile Methodologies and Frameworks'],

  title: 'Innovation, transformation and continuous improvement in your business',

  text1:
    'We provide daily guidance for managers and teams, ensuring that agile practices are applied throughout the company. This process assists your organization in Organizational and Digital Transformation and catalyzes a culture of continuous improvement.',

  text2:
    'The SIS Agile Coaching team works with the main agile methodologies and frameworks, helping organizations evaluate tools and implement advanced techniques, with complete follow-up in order to achieve the so-called "agile maturity".',

  text3:
    'We also provide training for Agile Coaches, Scrum Masters, Project Managers, Business Managers, and development teams, as well as a mentoring group to meet all your business needs.',
}
