export default {
  banner: ['Soluciones', 'ERP'],

  title: 'Usando datos para impulsar negocios y promover transformaciones digitales',

  text1:
    'Nuestro sistema integrado de gestión empresarial optimiza el flujo de trabajo y viabiliza el acceso a las informaciones en tiempo real, integrando y unificando los dados y procesos. Trabajamos con la modernización de los sistemas ERP, lo que mejora el apoyo al cliente y habilita su visión de 360 grados, además de cualificar a la empresa para la Transformación Digital.',

  text2:
    'Por medio de la integración de los datos, SIS facilita el uso de business analytics, inteligencia artificial, machine learning y big data, colocando a la empresa en el tope con total seguridad y compromiso. Para dar plena transparencia a la ejecución de las actividades de sustentación, además del SLA, también se ofrece el seguimiento de los procesos y se emiten informes mensuales con todos los indicadores de esa operación.',
}
