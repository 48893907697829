export default {
  banner: ['Strategic', 'Consulting'],

  title: 'The best strategy for your company',

  text1:
    'The best strategy for your company: using information technology in favor of organization, management, and control, we provide strategy development, which seeks to meet the main corporate challenges.',

  text2:
    'Focused on obtaining relevant results, the process consists in identifying business needs or opportunities. The mapping of scenarios is carried out and, subsequently, strategies are developed that, by means of innovative solutions, allow the established objectives to be reached.',
}
