export default {
  howWeDo: {
    title: 'Como Fazemos?',
    cards: [
      {
        title: 'Cada cliente é único',
        text: 'Ouvimos as dificuldades, diagnosticamos as necessidades, indicamos e executamos os produtos e serviços mais adequados para os melhores resultados.',
      },
      {
        title: 'Vivenciamos a realidade do cliente',
        text: 'Nosso time de especialistas tem habilidades para se aprofundar nos detalhes do mercado do cliente e atender de maneira mais ágil, eficiente e personalizada as suas necessidades.',
      },
      {
        title: 'Proximidade',
        text: 'Nos envolvemos com os desafios dos nossos clientes com confiança e comprometimento.',
      },
    ],
  },
}
