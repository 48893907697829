export default {
  banner: ['Consultoria', 'Estratégica'],

  title: 'A melhor estratégia para a sua empresa',

  text1:
    'Utilizando a tecnologia da informação em favor da organização, gestão e controle, disponibilizamos o desenvolvimento de estratégias, que busca atender os principais desafios corporativos.',

  text2:
    'Com foco na obtenção de resultados relevantes, o processo consiste na identificação de necessidades ou oportunidades de negócio. É realizado o mapeamento de cenários e, posteriormente, são desenvolvidas estratégias que, por meio de soluções inovadoras, permitem alcançar os objetivos estabelecidos.',
}
