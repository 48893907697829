export default {
  banner: ['Transformación', 'Digital'],

  title: 'Tecnología a favor del cambio cultural corporativo',

  text1:
    'La transformación digital es una tendencia del mercado actual y es cada vez más necesaria para que las empresas ofrezcan soluciones enfocadas en tecnologías innovadoras, personalizadas y pensadas para las necesidades de cada cliente.',

  text2:
    'SIS desempeña un papel fundamental ante sus clientes, ofreciendo servicios de desarrollo, análisis de negocios, pruebas, homologación e infraestructura, proporcionando toda una estructura dedicada al proceso de transformación digital.',

  text3:
    'Procurando ofrecer soluciones ágiles, innovadoras y tecnológicas y siguiendo las mejores prácticas y tendencias del concepto de transformación digital aplicadas al universo corporativo, trabajamos con foco en el modelo de negocio del cliente, implementando el proceso de forma inteligente.',
}
