export default {
  metatag: {
    title: 'Reclutamiento y selección de TI - SIS Innov & Tech',
    description:
      'Reclutamiento y Selección especializada en TI. Conozca nuestro servicio de hunting en tecnología, enfocado en traer agilidad y calidad para su empresa.',
  },
  title1: '',
  titleBold: 'Atracción y selección by SIS Tech. Hunting',

  agilityAndEffectiveness: `<b class="font-black">Agilidad y calidad </b> en la atracción y selección de profesionales para su empresa.<br><br> Reuniendo los mejores candidatos y las empresas clientes, realizamos un proceso selectivo personalizado y ágil, utilizando las mejores prácticas y herramientas de mercado.`,

  mainBarriersEncountered: 'Las principales barreras son:',

  mainBarriersEncounteredExtra:
    'Sabemos que actualmente la tecnología es parte de la gestión estratégica de personas, y que la contratación de profesionales de esta área no es algo muy simple.',

  list: [
    '• Demanda mayor que la oferta',
    '• Mutabilidad del área de TI.',
    '• Competitividad de la remuneración',
    '• Trípode: perfil del cargo, remuneración y experiencia comprobada',
    '• Habilidades técnicas y conductuales',
  ],

  title2: 'Reclutamiento Humanizado',
  text3:
    'Tech. Hunting utiliza su experiencia de casi 20 años en el ramo de TI. para facilitar todo el proceso de atracción y selección de candidatos para su empresa. Por medio de un servicio humanizado, hecho por personas para personas, con un equipo extremamente calificado para evaluar las habilidades conductuales y técnicas, identificamos el perfil ideal del profesional que su empresa está buscando.',
  text4:
    '"Realizamos el trabajo de hunting de forma humanizada. Entregamos solo candidatos calificados y aprobados técnicamente, pues tenemos especialistas internos para los más variados perfiles de TI, que nos apoyan en todas las entrevistas técnicas." Cintia Santos - Gerente de Atracción y Selección',
  button1: 'Pulse y hable con nuestros especialistas',

  title3: 'Precisión y rapidez son la clave del negocio',
  text5:
    'Tech. Hunting valora la agilidad en sus procesos, sin perder la calidad del trabajo pues entiende que el tiempo es un bien precioso y la urgencia de las demandas exige rapidez. Por eso, pone a su disposición un equipo especializado en identificar talentos compatibles con lo que su empresa necesita.',
  text6:
    'Con metodologías dinámicas, todos los procesos se desarrollan y se aplican de forma personalizada, para satisfacer asertivamente lo que su empresa requiere.',

  title4: 'Como acelerar su jornada',
  carousel: [
    {
      title: 'Intercambio',
      text: 'Con un diálogo franco y dirigido queremos entender sus necesidades específicas y las demandas del caso. Así, trabajamos en conjunto en la construcción de los perfiles y en el mapeo del mercado para buscar los profesionales más adecuados a las posiciones abiertas.',
    },
    {
      title: 'Personalización',
      text: 'Nuestro equipo desarrolla una estrategia enfocándose en plazo, mercado y perfil técnico/conductual, para cumplir las necesidades específicas de cada cargo, y pone a su disposición el equipo necesario para ejecutarla.',
    },
    {
      title: 'Ejecución',
      text: 'Aprobada la estrategia, es hora de la acción. El equipo de Atracción y Selección de Tech. Hunting pone en práctica toda su experiencia para captar y atraer a los mejores talentos y entregarlos en el mejor plazo posible.',
    },
    {
      title: 'Evolución',
      text: 'Luego de definir el mejor candidato y finalizar el proceso de selección y contratación, su empresa estará preparada para producir muchos más resultados y volar cada vez más alto.',
    },
  ],
  button2: 'Pulse y acelere su jornada con Tech. Hunting by SIS',

  title5: 'Quiénes somos',
  text7:
    'Tech. Hunting nació de la comprensión de que existe la necesidad de un mercado especializado en la contratación de profesionales de TI. Luego de estructurar un equipo estratégico, con entregas exitosas para los clientes de SIS, considerando el tiempo, los conocimientos técnicos y la importancia de cada proyecto o posición, nuestro gran diferencial está siendo nuestro SLA exclusivo. Esas entregas nos proporcionaron considerable experiencia y seguridad para ofrecer al mercado un servicio de hunting con competencia técnica, asertividad y agilidad.',
  title6: 'Quiénes son nuestros clientes',
  text8:
    'Empresas con demandas de vacantes activas en el mercado, principalmente de Tecnología. ¡Pero si su empresa está en otro nicho y necesita servicios de atracción y selección, Tech. Hunting también puede ayudarlo!',
  text9: '¡Hable con nosotros!',
  title7: 'Cómo actuamos',
  text10:
    'Tech. Hunting llegó para satisfacer con absoluta precisión las demandas de atracción y selección de personal de sus clientes. Nuestra forma de trabajar se basa en datos, ofreciendo servicios de hunting y presentando diferenciales con indicadores reales.',

  informativeText: '*para hasta 5 vacantes simultáneas',

  text11: 'Tenemos un cronograma de actuación audaz pues entregamos al cliente, ',

  textBold11: '3 candidatos aprobados en 5 días hábiles,',

  text12: 'lo que demuestra un servicio de entrega en tiempo récord.',

  button3: 'Pulse y contrate más rápido con Tech. Hunting by SIS',

  titleRD: 'y habla con nuestros expertos',
  titleRDBold: 'Completa el formulario',
  metaDescription:
    'Reclutamiento y Selección especializado en TI. Descubre nuestro servicio de caza tecnológica, enfocado en aportar agilidad y calidad a tu empresa.',
  form: {
    labelHowManyNeed: 'Necesito:*',
    howManyNeedList: [
      { id: 1, text: '01 al 10 profesionales' },
      { id: 2, text: '10 al 30 profesionales' },
      { id: 3, text: '30 al 50 profesionales' },
      { id: 4, text: '50 o más' },
    ],
  },
}
