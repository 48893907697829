import filters from './filters'

export default {
  blog: {
    title: ['Novedades', 'SIS'],
    subtitle: 'Acompañe las novedades sobre el mundo de la tecnología y soluciones SIS',
    button: 'Lea todo',

    filters: {
      ...filters,
    },
  },
}
