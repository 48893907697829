export default {
  banner: ['Web, Mobile, and Mainframe', 'Development'],

  title: 'More than 20 years’ experience',

  text1: 'We are passionate about technology!',

  text2:
    'The objective of SIS Consulting is to develop projects that contribute to the growth of your company. It has more than 20 years’ experience in the market, developing systems that meet the needs of each client.',

  text3: 'Web and Mobile Development',

  text4:
    'The development of Web and Mobile systems aims to implement customized solutions for each client. We work in all phases of project development, from conception to delivery, and with various languages and frameworks.',

  text5:
    'Our professionals are constantly learning and updated with the best market practices, in order to keep providing the best product for our clients.',

  text6: 'Mainframe',

  text7: 'Complete reliability and performance',

  text8:
    'SIS consulting specializes in high platform and in the development and sustainability of high availability systems. Our solutions include the processing of large volumes of data and allow the stability, security and reliability of the information.',

  text9:
    'In an agile and safe manner, our vast experience in mainframe projects allow us to guide our clients on the best paths to follow in order to meet the shared needs. Whether to make improvements to existing processes or develop and restructure complete systems, count on SIS for the best results.',
}
