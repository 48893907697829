import navbar from './navbar'
import footer from './footer'
import home from './home/index'
import weAreSis from './weAreSis/index'
import solutions from './solutions'
import innovation from './innovation'
import career from './career'
import blog from './blog'
import contact from './contact'
import beSis from './beSis'
import lgpd from './lgpd'
import pageNotFound from './pageNotFound'
import products from './products'
import transformation from './transformation'
import shared from './shared'
import clientsAndPartners from './clientsAndPartners'
import servicesProducts from './servicesProducts'

export default {
  ...servicesProducts,
  ...navbar,
  ...footer,
  ...home,
  ...weAreSis,
  ...solutions,
  ...innovation,
  ...career,
  ...blog,
  ...contact,
  ...beSis,
  ...lgpd,
  ...pageNotFound,
  ...products,
  ...transformation,
  ...shared,
  ...clientsAndPartners,
  codeEthics: {
    metatag: {
      title: 'Code of ethics and conduct - SIS Innov & Tech',
      description:
        'In our Code of Ethics & Conduct, we explain how we conduct all activities, both internal and external, based on this guide.',
    },
  },
  cookies: {
    metatag: {
      title: 'Cookie policy - SIS Innov & Tech',
      description:
        'In our Cookie Policy, we explain what cookies are, and how and why we use the information they capture.',
    },
  },
  privacyPolicy: {
    metatag: {
      title: 'Privacy policy - SIS Innov & Tech',
      description:
        'Our Privacy Policy applies to all information collected on our website. We provide information on how we collect, use and handle your personal data.',
    },
  },
}
