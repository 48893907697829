export default {
  howWeDo: {
    title: '¿CÓMO LO HACEMOS?',
    cards: [
      {
        title: 'Cada cliente es único',
        text: 'Escuchamos las dificultades, diagnosticamos las necesidades, indicamos y ejecutamos los productos y servicios más adecuados para los mejores resultados.',
      },
      {
        title: 'Vivenciamos la realidad del cliente',
        text: 'Nuestro equipo de especialistas tiene habilidades para profundizar en los detalles del mercado del cliente y cumplir de manera más ágil, eficiente y personalizada sus necesidades.',
      },
      {
        title: 'Proximidad',
        text: 'Nos involucramos con los desafíos de nuestros clientes con confianza y compromiso.',
      },
    ],
  },
}
