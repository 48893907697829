export default {
  products: {
    banner: 'O que Fazemos',
    title1: 'De Pessoas para Pessoas',
    text1: [
      'Somos uma empresa de Inteligência Tecnológica especializada em Inovação e Transformação Digital. Combinamos tecnologia e humanização, promovendo as melhores soluções do mercado para empresas de todos os segmentos.',
      'O nosso propósito é levar o que existe de mais tecnológico para que os seus negócios se desenvolvam, por meio de metodologias ágeis e ferramentas que tornam os processos mais simplificados.',
    ],
    title2: 'Inovação e Transformação Digital',
    text2:
      'A Inovação e Transformação Digital são as nossas especialidades. Dos insights às ações, fornecemos o atendimento completo, com segurança, qualidade e personalização.',
    button1: 'Quero um serviço exclusivo',
    title3: 'Serviços',
    text3:
      'Explore o que há de mais tecnológico para que a sua empresa seja a líder no seu ramo, utilizando os nossos serviços.',
    button2: 'Quero um serviço exclusivo',
    title4: 'Nossos Parceiros',
    text4:
      'Temos parcerias com os mais reconhecidos softwares e programas do mundo. Nossos colaboradores possuem certificações para oferecerem o atendimento completo, de forma assertiva e segura.',
    title5: 'Fale com a SIS',
    text5: 'Caso você não tenha encontrado o que buscava em nossa aba de serviços, fale com a gente!',
    button3: 'Falar com a SIS',
    knowMore: 'leia mais',

    itemsDigitalTransformation: [
      {
        title: 'Desenvolvimento Multiplataforma',
        text: 'Desenvolver projetos que contribuam para o crescimento da sua empresa.',
      },
      {
        title: 'Dados e Inteligência',
        text: 'Os dados das suas empresas são capazes de te guiar para as decisões mais assertivas.',
      },
      {
        title: 'Quality Assurance',
        text: 'Quality Assurance representa a melhor estratégia para evitar problemas.',
      },
      {
        title: 'Cloud Computing',
        text: 'Permitem implantar e gerir diretamente ambientes de TI em nuvem...',
      },
      {
        title: 'Agile Coaching',
        text: 'Auxilia sua organização na Transformação Digital e Organizacional e promove...',
      },
      {
        title: 'Design Thinking',
        text: 'Processo não linear e interativo que nossas equipes usam para entender os usuários...',
      },
      {
        title: 'UX e UI',
        text: 'É um dos fatores decisivos para um usuário escolher entre você e seu concorrente.',
      },
      {
        title: 'DevOps',
        text: 'Práticas, ferramentas e filosofias culturais que visam aumentar a capacidade...',
      },
    ],

    itemsSolutions: [
      {
        title: 'Tech. Hunting',
        text: 'Agilidade e qualidade na atração e seleção de profissionais para a sua empresa.',
      },
      {
        title: 'Consultoria Estratégica',
        text: 'Utilizando a tecnologia da informação em favor da organização, gestão e controle...',
      },
      {
        title: 'Sustentação de Sistemas',
        text: 'Evitar os prejuízos que erros, panes ou bugs possam causar em seu negócio.',
      },
      {
        title: 'ERP',
        text: 'Nosso sistema integrado de gestão empresarial otimiza seu fluxo de trabalho...',
      },
      {
        title: 'CRM',
        text: 'Atendemos as suas necessidades de vendas, marketing e atendimento ao cliente.',
      },
      {
        title: 'Low Code',
        text: 'Novas tecnologias que permitem a transformação digital do seu negócio.',
      },
      {
        title: 'Células Ágeis - Squads',
        text: 'Montamos um time de desenvolvimento completo, composto...',
      },
    ],

    metatag: {
      title: 'Inteligência Tecnológica especializada em Inovação e Transformação Digital.',
      description:
        'Elaboramos e colocamos em prática uma estratégia para impulsionar os seus negócios, unindo processos, pessoas e tecnologia de alta performance.',
    },
  },
}
