export default {
  banner: ['Consultoría', 'Estratégica'],

  title: 'La mejor estrategia para su empresa',

  text1:
    'La mejor estrategia para su empresa: utilizando la tecnología de la información en favor de la organización, gestión y control, proporcionamos el desarrollo de estrategias, que procuran atender los principales desafíos corporativos.',

  text2:
    'Enfocado en obtener resultados relevantes, el proceso consiste en identificar necesidades u oportunidades de negocio. Se realiza el mapeo de escenarios y,  posteriormente, se desarrollan estrategias que, por medio de soluciones innovadoras, permiten alcanzar los objetivos establecidos.',
}
