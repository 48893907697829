export default {
  banner: 'DevOps',

  title1: 'Liderando la transformación de sus operaciones',

  text1:
    'La cultura DevOps combina prácticas, herramientas y filosofías culturales que intentan aumentar la capacidad de las empresas de ofrecer softwares y servicios, con mayor agilidad que en procesos convencionales, acelerando la entrega de valor al cliente.',

  text2: '¡Metodologías y herramientas ágiles optimizando su desarrollo!',

  button1: 'Quiero optimizar mi negocio',

  title2: 'Transformamos tus procesos de Desarrollo Ágil',

  text3:
    'Por medio de un equipo experimentado en la aplicación de abordajes de infraestructuras, como código (IaC), integración y entrega continuas (CI/CD), SIS ofrece servicios de punta a punta, basados en metodologías y herramientas modernas para ofrecer a los clientes siempre la mejor experiencia posible.',

  text4:
    'SIS conoce todo el proceso de desarrollo ágil de software y realiza un trabajo de evaluación, que procura entender cómo funcionan los procesos de los clientes sobre el desarrollo y la operación de softwares, todo para entregar soluciones eficientes para su empresa.',

  text5: 'SIS ofrece potentes soluciones DevOps para su negocio',

  button2: 'Conozca ahora!',

  title3: 'Más información sobre DevOps',
}
