export default {
  banner: ['SIS', 'Academy'],

  text1:
    'Check out the training and development academy based on the SIS way of winning! Here, we prepare our professionals to be always ahead and stand out in the job market.',

  text2:
    'At SIS Academy, we provide weekly interactions when we discuss issues such as organizational culture, agile methodologies, behavioral and technical topics, and the main trends of the corporate scenario.',

  text3: 'Workshops and Training',

  text4:
    'We promote lectures, workshops, and meetups with the purpose of offering continued education to our professionals.',

  text5:
    'SIS has partnered with the Alura platform to offer training to employees, with more than 1,200 different courses. The platform is 100% funded by SIS and offers technical and behavioral courses for the entire team. Through the platform, we create paths for agile, technical and behavioral development, aiming at the evolution and continuous learning of our professionals.',

  text6:
    "At SIS, we believe that investing in certification and training is the biggest differential for corporate success and sustainability. SIS has a transparent Certification Policy, which subsidizes the necessary certifications for the development of its employees in line with the company's business.",
}
