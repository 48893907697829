export default {
  banner: ['Cloud Computing', 'Services'],

  title: 'Remote, secure and agile sharing',

  text1:
    'Cloud access has emerged as a way to democratize information and improve the experience of those who deal with technological resources, whether personally or professionally.',

  text2:
    'SIS offers clients an ideal set of solutions, which allow them to directly deploy and manage cloud IT environments, with agility and security.',

  text3:
    'The perfect combination of infrastructure and platform ensures the saving of space, centralized information, and full user security.',
}
