export default {
  banner: ['Employer', 'Branding'],

  text1:
    'Na SIS, buscamos ser uma empresa melhor a cada dia, por isso, valorizamos pessoas e vitórias. Acreditando na importância dos momentos de interação, desta forma, todos os meses, reservamos um tempo para comemorar os aniversários do período e apresentar aos colaboradores tudo o que aconteceu: novos clientes, projetos, agenda de treinamentos e campanhas.',

  text2: 'Café com o CEO',

  text3:
    'Acreditamos em uma gestão participativa, desta forma, realizamos mensalmente o Café com o CEO, um momento dedicado a compartilhar ideias e sugestões para a empresa.',

  text4:
    'Nossos colaboradores se reúnem com toda a diretoria executiva e são ouvidos em uma conversa leve e descontraída. Essa é uma forma de valorizar a opinião de todos dentro da organização, o que tem gerado bons frutos.',

  text5: 'GYMPASS',

  text6:
    'O Gympass é um benefício que visa estimular a prática de exercícios e é oferecido para todos os colaboradores. Buscamos, assim, incentivar uma melhora na saúde e bem-estar de todos.',

  text7: 'Como funciona?',

  text8:
    'A SIS paga uma mensalidade para que todos tenham acesso a planos mais baratos e possam usufruir do benefício em uma grande rede de academias e estúdios. Além disso, o Gympass é uma plataforma que conecta colaboradores a parceiros que disponibilizam dicas de exercícios, meditação, planejamento nutricional e de qualidade de vida, bem como aulas online que ficam sempre disponíveis para acesso em qualquer lugar.',

  text9: 'De pessoas para pessoas',

  text10:
    'Na SIS, nossa preocupação é o bem-estar dos nossos colaboradores. Acreditamos que investir em uma base sólida, feita por pessoas, nos faz ir cada vez mais alto. Por isso, nossos funcionários contam com ações motivacionais que vão desde momentos de integração a cuidados com o corpo e a mente.',

  text11: 'Conheça algumas de nossas iniciativas:',

  cards: {
    item1: {
      title1: 'Massagem Terapêutica Sistêmica',
      description:
        'Com ênfase nos canais energéticos da Medicina Chinesa, nos pontos de assentimentos energéticos e emocionais da coluna. Um trabalho com objetivo de olhar o corpo físico e emocional no aspecto sistêmico familiar e das diversas formas de se relacionar na vida e com a vida.',
      title2: 'Para tratar',
      list1: ['✔ Estresse', '✔ Ansiedade', '✔ Pressão alta', '✔ Artrite'],
      list2: ['✔ Baixa imunidade', '✔ Má circulação', '✔ Alinhamento energético'],
    },

    item2: {
      title1: 'Quick Massage',
      description:
        'Os benefícios da quick massage podem ser sentidos imediatamente e só melhoram a cada sessão. O alívio de dores musculares, o relaxamento das regiões do pescoço, costas e braços e a prevenção das lesões por esforço repetitivo (LER’s) e dos distúrbios osteomusculares relacionados ao trabalho (DORT’s) são algumas de suas vantagens.',
      title2: 'Além disso, é comprovado que:',
      list1: ['✔ Melhora a produtividade', '✔ Diminui o índice de afastamento por doença'],
      list2: ['✔ Melhora a relação empresa - funcionário', '✔ Auxilia na manutenção da saúde e bem-estar da equipe'],
    },

    item3: {
      title1: 'Ginástica Laboral',
      description:
        'É realizada algumas vezes por semana, no próprio local de trabalho, pelos colaboradores que se interessam. A ginástica laboral se dá através da realização coletiva de atividades físicas, e dentre seus benefícios estão:',
      list1: ['✔ Combate ao sedentarismo', '✔ Melhora no condicionamento físico', '✔ Prevenção de doenças'],
      list2: ['✔ Combate ao stress e tensões', '✔ Redução do cansaço mental e físico', '✔ Melhora na autoestima'],
    },
  },

  text12: ['#Somos'],

  text13:
    'O ano de 2020 foi marcado com a nossa primeira participação e conquista do certificado Great Place to Work (GPTW), um reconhecimento dado às empresas que são referência em ambiente de trabalho no mundo!',

  text14:
    'Nosso sucesso é construído diariamente, em parceria com cada colaborador. Por meio de um time de grandes profissionais que atuam com muita dedicação e comprometimento, garantimos a entrega dos melhores serviços e soluções aos nossos clientes.',

  buttonContent: 'VENHA SER SIS',
}
