import banners from './banners'
import weAreSis from './weAreSis'
import cards from './cards'
import depositions from './depositions'
import performance from './performance'
import people from './people'
import partOfTeam from './partOfTeam'
import talkToSis from './talkToSis'

export default {
  home: {
    ...banners,
    ...weAreSis,
    ...cards,
    ...depositions,
    ...performance,
    ...people,
    ...partOfTeam,
    ...talkToSis,
    metatag: {
      title: 'SIS Innov & Tech - la mejor estrategia para su empresa',
      description:
        'SIS Innov & Tech es una consultoría estratégica de Innovación y Transformación Digital que proporciona lo que hay de más tecnológico para los negocios, con alto desempeño y compromiso.',
    },
  },
}
