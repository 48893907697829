export default {
  navbar: {
    sisResume:
      'Oferecemos serviços que transformam, impulsionam, facilitam<br>e otimizam. <span class="font-bold">Conheça todas as nossas soluções:</span>',
    cards: {
      first: {
        title: 'Transformação digital',
        buttonText: 'Acessar',
      },
      second: {
        title: 'Serviços',
        buttonText: 'Acessar',
      },
    },
    weAreSis: {
      title: 'Quem somos',
      itens: {
        about: 'Quem somos',
        clients: 'Clientes e Parceiros',
        successCases: 'Casos de Sucesso',
      },
    },
    solutions: {
      title: 'Serviços',
      itens: {
        hunting: 'Tech. Hunting',
        consulting: 'Consultoria Estratégica',
        development: 'Desenvolvimento Web, Mobile e Mainframe',
        sustentation: 'Sustentação de Sistemas',
        bi: 'Dados e Inteligência Aplicada',
        cloud: 'Serviços de Cloud Computing',
        erp: 'Soluções de ERP',
        lowCode: 'Soluções de Low Code',
        quality: 'Quality Assurance',
        crmSolutions: 'Soluções de CRM',
      },
    },
    innovation: {
      title: 'Inovação',
      itens: {
        agileCoaching: 'Agile Coaching e Frameworks Ágeis',
        design: 'Design Thinking',
        transformation: 'Transformação Digital',
        agileCells: 'Células Ágeis - Squads',
        ux: 'UX e UI',
        devOps: 'DevOps',
      },
    },
    products: {
      title: 'O que Fazemos',
    },
    career: {
      title: 'Carreira',
      itens: {
        talk: 'Employer Branding',
        sisAcademy: 'SIS Academy',
        apprenticeship: 'Programa de Formação',
      },
    },
    blog: {
      title: 'Blog',
    },
    contact: {
      title: 'Fale com a gente',
    },
    beASis: {
      title: 'NOSSAS VAGAS',
    },
    language: {
      title: 'PT-BR',
    },
    midia: {
      title: 'SIS na Mídia',
    },
    buttonContact:'Fale com a gente',
  },
}
