export default {
  banner: ['Soluciones', 'Low Code'],

  title1: 'Desarrollo de sistemas de forma ágil y simple',

  text1:
    'La transformación digital trajo tecnologías y tendencias que facilitan y mejoran la gestión en TI. Como una empresa que acompaña los movimientos y transformaciones del mercado, SIS ofrece a sus clientes las soluciones más certeras y eficaces para cada objetivo.',

  title2: 'Transformación Digital definitiva en su negocio',

  text2:
    'En línea con sus propósitos, SIS invierte en nuevas tecnologías que permiten la transformación digital definitiva de su negocio, poniendo a su disposición aplicaciones rápidas, tecnológicamente actualizadas, seguras y confiables, con completa integración y conectividad. Nuestros profesionales dominan con expertise el desarrollo de aplicaciones en low code, por medio de la plataforma PEGA, que posibilita la integración a través de APIS y está alineada con las metodologías ágiles de desarrollo.',
}
