export default {
  performance: {
    title: 'Entrega con Alta Performance y Compromiso',
    descriptionFirst:
      'Cada cliente es único. Escuchamos su desafío, entendemos sus necesidades, nos sumergimos en su realidad e indicamos los productos y servicios para los mejores resultados.',
    descriptionSecond:
      '¡Las empresas que adhieren a la tecnología en sus procesos están siempre a la cabeza en el mercado!',
    button: 'Más información',
    cards: {
      title: 'Esto es lo que <span class="font-bold">hacemos</span>',
      first: {
        title: 'Transformación digital',
        button: 'Más información',
      },
      second: {
        title: 'Servicios',
        button: 'Más información',
      },
    },
  },
}
