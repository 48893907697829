export default {
  item1: 'TECNOLOGIA',
  item2: 'INOVAÇÃO',
  item3: 'NOVIDADES',
  item4: 'DICAS',
  item5: 'FORMAÇÃO',
  item6: 'SERVIÇOS',
  item7: 'SAIBA O QUE A SIS PODE OFERECER',
  item8: 'SOBRE A SIS',
  item9: 'CASES DE SUCESSO',
  item10: 'QUER FAZER PARTE DO TIME SIS?',
  item11: 'CONFIRA AS VAGAS',
}
