export default {
  howWeAct: {
    title: 'Como Agimos',
    contentBold: 'Nossos valores são a base da nossa cultura organizacional.',
    content: 'Respeitando as individualidades, prezamos pela:',
    list: [
      '• Ética',
      '• Transparência',
      '• Valorização Humana',
      '• Integração',
      '• Inovação',
      '• Qualidade',
      '• Comprometimento',
    ],
  },
}
