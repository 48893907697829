export default {
  cards: {
    title: ['Our', 'Solutions'],
    card1: {
      title: ['Business', 'strategy/technology'],
      description:
        'We’re highly qualified and in line with the main market practices and trends. We constantly invest in our strategic and managerial staff, and in multidisciplinary teams, encompassing business, technical, and behavioral aspects.',
    },

    card2: {
      title: ['Digital', 'Transformation'],
      description:
        'We work on several projects for the development of new features, for different digital assets and service modalities, as well as in agile, web and mobile development.',
    },

    card3: {
      title: ['Application', 'Sustainability'],
      description:
        'We have a complete development team, consisting of Technical Architects, Product Owners, Scrum Masters, UX/UI, DevOps Developers and Engineers. Thus, we make it possible to develop new products and projects in an agile manner.',
    },

    card4: {
      title: ['Development', 'Squad'],
      description:
        'We provide corrective, adaptive, and evolutionary management of the technology systems used daily in a company.',
    },
  },
}
