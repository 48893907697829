export default {
  banner: ['Employer', 'Branding'],

  text1:
    'En SIS, procuramos ser cada día mejores, por eso, valorizamos personas y victorias. Creemos en la importancia de los momentos de interacción, por eso, todos los meses, reservamos un tiempo para celebrar los cumpleaños del período y presentar a los colaboradores todo lo que ha estado sucediendo: nuevos clientes, proyectos, programación de entrenamientos y campañas.',

  text2: 'Café con el CEO',

  text3:
    'Creemos en una gestión participativa, por eso realizamos mensualmente un Café con el CEO, un momento dedicado a compartir ideas y sugerencias para la empresa.',

  text4:
    'Nuestros colaboradores se reúnen con toda la dirección ejecutiva y participan en una conversación amena y  tranquila donde se los escucha. Es una forma de valorizar la opinión de todos dentro de la organización, lo que ha dado buenos frutos.',

  text5: 'GYMPASS',

  text6:
    'El Gympass es un beneficio que pretende estimular la práctica de ejercicios y es ofrecido a todos los colaboradores. Así procuramos promover la salud y el bienestar de todos.',

  text7: '¿Cómo funciona? ',

  text8:
    'SIS paga una mensualidad para que todos tengan acceso a planes más baratos y puedan disfrutar del beneficio en una gran red de gimnasios y estudios. Además, el Gympass es una plataforma que conecta colaboradores a aliados comerciales que ofrecen sugerencias de ejercicios, meditación, planificación nutricional y de calidad de vida, como también clases online que están siempre disponibles para acceso desde cualquier lugar.',

  text9: 'De personas para personas',

  text10:
    'En SIS, nuestra preocupación es el bienestar de nuestros colaboradores. Creemos que invertir en una base sólida, hecha por personas, nos hace llegar cada vez más lejos. Por eso, ofrecemos a nuestros empleados acciones motivacionales que van desde los momentos de integración al cuidado del cuerpo y de la mente.',

  text11: 'Conozca algunas de nuestras iniciativas:',

  cards: {
    item1: {
      title1: 'Masaje Terapéutico Sistémico',
      description:
        'Con énfasis en los canales energéticos de la Medicina China, en los puntos de asentimientos energéticos y emocionales de la columna. Es un trabajo con el objetivo de contemplar el cuerpo físico y emocional en el aspecto sistémico familiar y de las diversas formas de relacionarse en la vida y con la vida.',
      title2: 'Para tratar:',
      list1: ['✔ Estrés', '✔ Ansiedad', '✔ Presión alta', '✔ Artritis'],
      list2: ['✔ Baja inmunidad', '✔ Mala circulación', '✔ Alineación energética'],
    },

    item2: {
      title1: 'Quick Massage',
      description:
        'Los beneficios del quick massage se pueden sentir inmediatamente y mejoran a cada sesión. El alivio de dolores musculares, la relajación de las regiones del cuello, espalda y brazos y la prevención de lesiones por esfuerzo repetitivo y los síndromes osteomusculares relacionados con el trabajo (enfermedades profesionales) son algunas de sus ventajas.',
      title2: 'Além disso, é comprovado que:',
      list1: ['✔ Mejora la productividad', '✔ Disminuye el índice de licencias por enfermedad'],
      list2: ['✔ Mejora la relación empresa - empleado', '✔ Ayuda a mantener la salud y el bienestar del equipo'],
    },

    item3: {
      title1: 'Gimnasia Laboral',
      description:
        'Se realiza algunas veces por semana, en el propio lugar de trabajo, y participan los colaboradores interesados. La gimnasia laboral se realiza mediante actividades físicas colectivas, y estos son algunos beneficios:',
      list1: ['✔ Combate el sedentarismo', '✔ Mejora el condicionamiento físico', '✔ Previene enfermedades'],
      list2: [
        '✔ Combate el estrés y las tensiones',
        '✔ Reduce el cansancio mental y físico',
        '✔ Mejora la autoestima',
      ],
    },
  },

  text12: ['#Somos'],

  text13:
    'El año 2020 ha sido marcado por nuestra primera participación y la conquista del certificado Great Place to Work (GPTW), un reconocimiento que se da a las empresas que son referencias en materia de ambiente de trabajo ¡en el mundo!',

  text14:
    'Nuestro éxito se construye diariamente, en conjunto con cada colaborador. Por medio de un equipo de grandes profesionales que trabajan con mucha dedicación y compromiso, garantizamos la entrega de los mejores servicios y soluciones a nuestros clientes.',

  buttonContent: 'VENGA A TRABAJAR EN SIS',
}
