export default {
  products: {
    banner: 'Lo que hacemos',
    title1: 'De personas a personas',
    text1: [
      'Somos una empresa de Inteligencia Tecnológica especializada en Innovación y Transformación Digital. Combinamos tecnología y humanización, promoviendo las mejores soluciones del mercado para empresas de todos los segmentos.',
      'Nuestro propósito es llevar lo más tecnológico que existe para que sus negocios se desarrollen, por medio de metodologías ágiles y herramientas que simplifican los procesos.',
    ],
    title2: 'Innovación y Transformación Digital:',
    text2:
      'La Innovación y la Transformación Digital son nuestras especialidades. De insights a acciones, suministramos servicios completos, con seguridad, calidad y personalización.',
    button1: 'Quiero una solución personalizada',
    title3: 'Soluciones',
    text3:
      'Explore lo que hay de más avanzado en tecnología para que su empresa se torne una líder en su ramo, utilizando nuestros servicios.',
    button2: 'Quiero una solución exclusiva',
    title4: 'Nuestros aliados comerciales',
    text4:
      'Tenemos alianzas con los más reconocidos softwares y programas del mundo. Nuestros colaboradores tienen certificaciones para ofrecer un servicio completo, asertivo y seguro.',
    title5: 'Hable con SIS',
    text5: '¡Si no ha encontrado lo que buscaba en nuestra pestaña de servicios, contáctenos!',
    button3: 'Hable con SIS',
    knowMore: 'Más informaciones',

    itemsDigitalTransformation: [
      {
        title: 'Desarrollo multiplataforma',
        text: 'El objetivo de SIS Consultoría es desarrollar proyectos que contribuyan con...',
      },
      {
        title: 'Datos & Inteligencia Aplicada',
        text: 'Los datos de sus empresas son capaces de guiarlos a las decisiones más...',
      },
      {
        title: 'Quality Assurance',
        text: 'En SIS Consultoría, nos preocupamos con la calidad y eficiencia del producto...',
      },
      {
        title: 'Cloud Computing',
        text: 'El acceso a la nube surgió como una forma de democratizar informaciones y mejorar...',
      },
      {
        title: 'Agile Coaching y Frameworks',
        text: 'Suministramos orientación diaria para gerentes y equipos, garantizando que...',
      },
      {
        title: 'Design Thinking',
        text: 'En SIS, nos importamos con la valorización del ser humano. En las metodología...',
      },
      {
        title: 'UX e UI',
        text: 'La calidad de la UX es uno de los factores decisivos para que un usuario...',
      },
      {
        title: 'DevOps',
        text: 'La cultura DevOps combina prácticas, herramientas y filosofías culturales que...',
      },
    ],

    itemsSolutions: [
      {
        title: 'Tech. Hunting',
        text: 'Reuniendo los mejores candidatos y las empresas clientes, realizamos un proce...',
      },
      {
        title: 'Consultoría Estratégica',
        text: 'La mejor estrategia para su empresa: utilizando la tecnología de la informaci...',
      },
      {
        title: 'Sustentación de Ambientes',
        text: 'La sustentación de ambientes consiste en la gestión correctiva, adaptiva y ev...',
      },
      {
        title: 'ERP',
        text: 'Nuestro sistema integrado de gestión empresarial optimiza el flujo de trabajo...',
      },
      {
        title: 'CRM',
        text: 'SIS Consultoría actúa en proyectos de CRM, utilizando el ecosistema Salesforc...',
      },
      {
        title: 'Low Code',
        text: 'La transformación digital trajo tecnologías y tendencias que facilitan y mejo...',
      },
      {
        title: 'Células Ágiles',
        text: 'La Transformación Digital está haciendo que muchas empresas procuren la reest...',
      },
    ],

    metatag: {
      title: 'Consultoría en Transformación Digital - SIS Innov & Tech',
      description:
        'Llevamos la transformación digital y la innovación de forma personalizada, con estrategias específicas para atender las necesidades de cada uno de nuestros clientes.',
    },
  },
}
