import blog from './shared/blog'

export default {
  ...blog,
  pageBlogList: {},
  meta: {
    title: 'SIS Innov & Tech - Blog',
    description: 'The best content, created by technology experts!',
  },
}
