export default {
  appliedIntelligence: {
    banner: ['Data & Applied', 'Intelligence'],

    data: {
      title: 'Data driving your business’ intelligence! Dados impulsionando a inteligência do seu negócio!',
      text1:
        "<span class='font-bold'>BI, Big Data and Analytics Solutions</span> <br><br>Your company's data is capable of guiding you in the most assertive decisions within the context of your business. That's why our solutions for collecting, distributing and analyzing structured and unstructured data improve your work processes and help you gain valuable insights into market trends. SIS has the goal of facilitating the discovery of opportunities that are beyond what the human brain can organically process.",
    },

    leverage: {
      title: 'Rely on SIS to leverage your business and promote digital transformation!',
      button: 'I want to grow my business',
    },

    cover: {
      title:
        'We cover the main data management, integration and processing technologies and offer the best solutions for:',
      list1: [
        'Big Data',
        'Inteligência Artificial (AI)',
        'Machine Learning',
        'Deep Learning',
        'Business Intelligence',
        'Data Warehouse',
        'Analytics',
      ],
      list2: ['Data Lake', 'Data Mart', 'Data Science', 'Data Governance', 'MDM', 'Data Quality & MDM'],
    },

    solutions: {
      title: 'Solutions that encompass descriptive, predictive, and prescriptive models.',
      text1:
        'Artificial Intelligence (Machine Learning & Deep Learning) is one of the pillars of Digital Transformation, which is essential for an innovative, efficient, and sustainable organization. Companies around the world have been looking for a way to identify where and how to generate business value through Artificial Intelligence. This is part of the Digital Transformation process, which allows the automation of processes and the improvement of data-driven decisions.',
    },

    impulse: {
      title:
        'At SIS, we have extensive experience in Machine Learning and Deep Learning, which are techniques that provide great potential benefits for your business.',
      button: 'Boost your business with AI!',
    },
  },
}
