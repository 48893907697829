import about from './about/index'
import successCases from './successCases'

export default {
  weAreSis: {
    about: {
      ...about,
    },

    successCases: {
      ...successCases,
    },

    clients: {
      banner: ['Clientes e Parceiros', 'SIS', 'preparados para ir além do trabalho.'],
      title1: 'Clientes',
      title2: ['Parceiros', 'e Principais Tecnologias'],
    },
  },
}
