export default {
  howWeAct: {
    title: 'How we operate!',
    contentBold: 'Our values are the basis of our organizational culture.',
    content: 'Respecting individuality, we value:',
    list: [
      '• Ethics',
      '• Transparency',
      '• Human Appreciation',
      '• Integration',
      '• Innovation',
      '• Quality',
      '• Commitment',
    ],
  },
}
