export default {
  banner: ['Transformação', 'Digital'],

  title: 'Tecnologia a favor da mudança cultural corporativa',

  text1:
    'A transformação digital é uma tendência do mercado atual e vem se fazendo necessária para que as empresas ofereçam soluções que foquem em tecnologias inovadoras, personalizadas e pensadas para a necessidade de cada cliente.',

  text2:
    'A SIS desempenha um papel fundamental junto aos seus clientes, oferecendo serviços de desenvolvimento, análise de negócios, testes, homologação e infraestrutura, proporcionando, assim, toda uma estrutura voltada para o processo de transformação digital.',

  text3:
    'Em busca de oferecer soluções ágeis, inovadoras e tecnológicas, seguindo as melhores práticas e tendências para o conceito de transformação digital aplicada ao universo corporativo, trabalhamos com foco no modelo de negócio do cliente, fazendo com que o processo seja implementado de forma inteligente.',
}
