export default {
  banner: ['SIS', 'Training Program'],

  text1:
    'SIS has created this program to offer theoretical and practical knowledge to interns, trainees, young apprentices and professionals in career transition, making them more competitive and able to meet SIS and its clients’ needs, besides being better prepared to work on projects within the company.',

  text2:
    'The goal is to help interns, trainees, or young apprentices to believe in their potential and develop awareness about important issues in professional life, through concepts and on-the-job experience, associated with a practical approach, provided through group dynamics and/or gamification.',

  text3: 'To whom does it apply?',

  cards: {
    item1: {
      title: 'Internship',
      text: 'The time has come for you to put into practice all the knowledge you have acquired through your studies, by means of the SIS TRAINING PROGRAM. Besides acquiring knowledge about the business world, you will participate in on-the-job projects, under the specialized guidance of our best professionals, to expand your horizons and invest in your personal and professional growth.',
      buttonLink: '/trabalhe-conosco/estagio',
      buttonContent: 'VIEW THE JOB OPENINGS',
    },
    item2: {
      title: 'Trainee',
      text: 'Make the first steps in your career worthwhile through the SIS TRAINING PROGRAM. Work in important projects, obtain guidance from experienced leaders, test your ideas and grow with us.',
      buttonLink: '/trabalhe-conosco/trainee',
      buttonContent: 'VIEW THE JOB OPENINGS',
    },
    item3: {
      title: 'Young Apprentice',
      text: 'Start your professional life in a company that invests in hiring, training, and developing young people through the SIS TRAINING PROGRAM.',
      buttonLink: '/trabalhe-conosco/jovem-aprendiz',
      buttonContent: 'VIEW THE JOB OPENINGS',
    },
  },

  text4: ['Fundamental Premises of the', 'SIS Training Program'],

  text5: '✔ To offer qualified professionals to our clients who understand the current moment of the market.',

  text6: '✔ Invest in the selection, hiring, training and retention of interns, young apprentices and trainees.',

  text7: '✔ Develop training programs that adhere to market needs in order to be more competitive.',

  icons: {
    item1: {
      title: 'Target Audience',
      text: ['Interns | Trainees | Young Apprentices'],
      icon: 'img2.webp',
    },
    item2: {
      title: 'Modules',
      text: ['Technical | Behavioral | Agile Practices and Leadership | Business'],
      icon: 'img3.webp',
    },
    item3: {
      title: 'Development Plan',
      text: [
        'up to 2 years, with possible employment starting in the 7th month. Performance adjustment every 06 months',
        'Duration:',
      ],
      icon: 'img6.webp',
    },
  },

  text8: 'Testimonials',

  text9:
    'The Training Program is very valuable at SIS, and it makes us proud and certain that we’re on the right track:',

  text10: 'Partnership:',
}
