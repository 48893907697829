export default {
  howWeAct: {
    title: '¡CÓMO ACTUAMOS!',
    contentBold: 'Nuestros valores son la base de nuestra cultura organizativa.',
    content: 'Respetando las individualidades, nos preocupamos por la:',
    list: [
      '• Ética',
      '• Transparencia',
      '• Valorización Humana',
      '• Integración',
      '• Innovación',
      '• Calidad',
      '• Compromiso',
    ],
  },
}
