export default {
  banner: ['Células', 'Ágiles'],

  title: '¡Un squad completo dedicado a Ud.!',

  text1:
    'La Transformación Digital está haciendo que muchas empresas procuren la reestructuración para viabilizar los nuevos negocios digitales. Aquí, Ud. cuenta con un squad dedicado a ayudarlo en su proceso, con el objetivo de aumentar la productividad y eficiencia de su equipo.',

  text2:
    'Montamos un equipo de desarrollo completo, compuesto por arquitectos técnicos, product owners, Scrum Masters, UX/UI, desarrolladores e ingenieros DevOps. Por medio de estos profesionales de todas las habilidades, posibilitamos el desarrollo de nuevos productos y proyectos de forma ágil.',
}
