export default {
  item1: 'Tecnología',
  item2: 'Innovación',
  item3: 'Novedades',
  item4: 'Consejos',
  item5: 'Formación',
  item6: 'Soluciones',
  item7: 'Vea lo que SIS puede ofrecerle',
  item8: 'SOBRE SIS',
  item9: 'Cases de éxit',
  item10: '¿Quiere formar parte del equipo de SIS?',
  item11: 'Verifique nuestras vacantes ',
}
