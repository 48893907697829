export default {
  banner: ['User Experience y User Interface', '(UX/UI)'],

  title1: '¿Ud. sabe de qué manera la UX afecta su empresa?',

  text1:
    'La calidad de la UX es uno de los factores decisivos para que un usuario escoja entre Ud. y su competidor, después de todo, el nivel de ambición, los procesos internos y todo un conjunto de herramientas necesarias para suministrar la experiencia apropiada modelan su organización.',

  text2:
    'El UX Design es el catalizador para reunir el usuario, la tecnología y la empresa, todo eso por medio de la creación de interfaces. Nuestra meta es entregar un proyecto que, interacción por interacción, torne la vida del usuario mejor.',

  title2: 'Además de funcional, la UI lo hace todo más agradable',

  text3: 'Interacción, usabilidad y apariencia: ¡todo lo que Ud. espera!',

  text4:
    'El trabajo de los UI Designers de SIS representa todo lo que se utiliza en la interacción con el producto. Ellos son responsables de la creación de interfaces funcionales, que permiten que el usuario navegue con la mejor experiencia por una plataforma que va mucho más allá de su belleza.',

  text5:
    'Nuestro objetivo es la entrega final de una interfaz, ya sea visual o no, que garantice máxima interacción, performance y usabilidad: entendemos la jornada del usuario, aplicamos y construimos los mejores caminos en búsqueda de la excelencia.',
}
