export default {
  cards: {
    title: ['Nossas', 'Soluções'],
    card1: {
      title: ['Estratégia ', 'de negócio/tecnologia'],
      description:
        'Somos altamente qualificados e alinhados com as principais práticas e tendências do mercado. Investimos constantemente no corpo estratégico, gerencial e nas equipes multidisciplinares, contemplando aspectos de negócio, técnicos e comportamentais.',
    },

    card2: {
      title: ['Transformação', 'Digital'],
      description:
        'Atuamos em diversos projetos de desenvolvimento de novas funcionalidades, para diferentes ativos digitais e modalidades de serviços, bem como no desenvolvimento ágil, web e mobile.',
    },

    card3: {
      title: ['Sustentação', 'de Aplicações'],
      description:
        'Temos um time completo de desenvolvimento, composto por arquitetos técnicos, Product Owners, Scrum Masters, UX/UI, desenvolvedores e engenheiros DevOps. Assim, possibilitamos o desenvolvimento de novos produtos e projetos de forma ágil.',
    },

    card4: {
      title: ['Squad de', 'Desenvolvimento'],
      description:
        'Oferecemos uma gestão corretiva, adaptativa e evolutiva dos sistemas de tecnologia utilizados diariamente em uma empresa.',
    },
  },
}
