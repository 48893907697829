export default {
  banner: ['SIS', 'Academy'],

  text1:
    'Conheça a academia de treinamento e desenvolvimento pautada no jeito SIS de vencer! Aqui preparamos nossos profissionais para estarem sempre à frente e em destaque no mercado de trabalho.',

  text2:
    'Na SIS Academy proporcionamos interações semanais, quando discutimos questões como a cultura organizacional, metodologias ágeis, temas comportamentais e técnicos e as principais tendências do cenário corporativo.',

  text3: 'Workshops e Treinamentos',

  text4:
    'Promovemos palestras, workshops e meetups com o propósito de oferecer formação continuada aos nossos profissionais.',

  text5:
    'A SIS tem parceria com a plataforma da Alura para oferecer treinamentos aos colaboradores. São mais de 1.200 cursos diferentes. A plataforma é 100% custeada pela SIS e oferece cursos técnicos e comportamentais para toda a equipe. Por meio da plataforma, criamos trilhas de desenvolvimento ágil, técnico e comportamental, visando a evolução e o aprendizado contínuo dos profissionais.',

  text6:
    'Na SIS, acreditamos que investir em certificação e treinamento é o maior diferencial para o sucesso e sustentabilidade corporativa. A SIS tem uma Política de Certificação transparente, que subsidia as certificações necessárias para o desenvolvimento de seus colaboradores alinhado aos negócios da empresa.',
}
