import about from './about/index'
import successCases from './successCases'

export default {
  weAreSis: {
    about: {
      ...about,
    },

    successCases: {
      ...successCases,
    },

    clients: {
      banner: ['Clientes y Aliados', 'SIS', 'Un camino de éxito conjunto.'],
      title1: 'Algunos de nuestros clientes:',
      title2: ['Aliados', 'comerciales de SIS y principales tecnologías:'],
    },
  },
}
