export default {
  banner: ['Agile', 'Cells'],

  title: 'A complete squad dedicated to you!',

  text1:
    'Digital Transformation is driving many companies to restructure their organizations to enable new digital businesses. Here, you can count on a dedicated squad to support your process, aiming to increase your team’s productivity and efficiency.',

  text2:
    'We set up a complete development team, consisting of technical architects, product owners, Scrum Masters, UX/UI, DevOps developers and engineers. Through these professionals who have all these skills, we enable the agile development of new products and projects.',
}
