export default {
  banner: ['Células', 'Ágeis'],

  title: 'Uma squad completa dedicada a você!',

  text1:
    'A Transformação digital vem fazendo com que várias empresas busquem a reestruturação de suas organizações para viabilizar os novos negócios digitais. Aqui, você conta com uma squad dedicada a auxiliar seu processo, com o objetivo de aumentar a produtividade e eficiência da sua equipe.',

  text2:
    'Montamos um time de desenvolvimento completo, composto por arquitetos técnicos, product owners, Scrum Masters, UX/UI, desenvolvedores e engenheiros DevOps. Por meio desses profissionais de todos os skills, possibilitamos o desenvolvimento de novos produtos e projetos de forma ágil.',
}
