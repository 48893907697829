import apprenticeship from './apprenticeship'
import employerBranging from './employerBranging'
import inovaSis from './inovaSis'

export default {
  career: {
    apprenticeship: {
      ...apprenticeship,
    },

    employerBranging: {
      ...employerBranging,
    },

    inovaSis: {
      ...inovaSis,
    },
  },
}
