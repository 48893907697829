export default {
  banner: ['Design', 'Thinking'],

  title1: 'Human beings at the center',

  text1:
    'At SIS, we value human beings. In the development methodologies we adopt, it would be no different. Design Thinking is a non-linear and interactive process that our teams use to understand users, challenge assumptions, redefine problems and create innovative solutions for prototyping and testing.',

  title2: 'Empathize, Define, Conceive, Prototype and Test',

  text2: 'The key to innovation',

  text3:
    "SIS' goal with the Design Thinking projects is to promote innovation, channeling efforts to your needs, understanding your market and tools, and thus disseminating its value proposition in line with the relevant goals. We act by promoting:",

  list: {
    item1: '✔ Solutions to the dilemmas and challenges;',
    item2: '✔ Logical business modeling;',
    item3: '✔ Purposes in line with innovation and the rise of your business;',
    item4: '✔ The development of multidisciplinary and integrated teams;',
    item5: '✔ The development of innovative products and services geared to the needs of your consumer',
  },

  text4: 'Specialized consulting',

  text5:
    "SIS' guided leadership enables managers to best direct their organizations’ points. The technical knowledge in specialized consulting helps co-create benefits for your business, affirming the agile and efficient management that Design Thinking offers.",
}
