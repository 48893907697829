export default {
  shared: {
    validations: {
      requiredField: 'O campo {fieldName} é obrigatório.',
      minAndMaxChars: 'O campo deve ter no mínimo {min} e no máximo {max} characteres.',
      maxChars: 'O campo deve ter no máximo {max} characteres.',
      emailInvalid: 'Endereço de e-mail inválido.',
      urlInvalid: 'URL inválida.',
    },
  },
}
