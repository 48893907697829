export default {
  banner: ['Programa', 'de Formación SIS'],

  text1:
    'Este es un programa creado por SIS para ofrecer conocimiento teórico y práctico a los estudiantes en entrenamiento, jóvenes aprendices y profesionales en transición de carrera, tornándolos más competitivos y capacitados para atender las necesidades de SIS y de sus clientes, y para estar más preparados para actuar en los proyectos dentro de la empresa.',

  text2:
    'El objetivo es contribuir para que los estudiantes en entrenamiento, y los jóvenes aprendices comiencen a creer en su potencial y desarrollen consciencia sobre temas importantes de la vida profesional, por medio del conocimiento de conceptos y de la experiencia on-the-job, asociada al abordaje práctico proporcionado por dinámicas y/o gamificación.',

  text3: '¿A quién se aplica?',

  cards: {
    item1: {
      title: 'Pasantía',
      text: 'Llegó la hora de poner en práctica todo el aprendizaje adquirido con tus estudios, por medio del PROGRAMA DE FORMACIÓN SIS. Además de adquirir conocimientos sobre el mundo empresarial, participarás de proyectos, on-the-job, con la orientación especializada de nuestros mejores profesionales para poder expandir tus horizontes e invertir en tu crecimiento personal y profesional.',
      buttonLink: '/trabalhe-conosco/estagio',
      buttonContent: 'ACCEDE A LAS VACANTES ',
    },
    item2: {
      title: 'Pasante',
      text: 'Haz que los primeros pasos en tu carrera valgan la pena a través del PROGRAMA DE FORMACIÓN SIS. Actúa en proyectos importantes, recibe orientación de líderes experimentados, prueba tus ideas y crece con nosotros.',
      buttonLink: '/trabalhe-conosco/trainee',
      buttonContent: 'ACCEDE A LAS VACANTES ',
    },
    item3: {
      title: 'Joven Aprendiz',
      text: 'Comienza tu vida profesional en una empresa que invierte en la contratación, entrenamiento y desarrollo de jóvenes a través del PROGRAMA DE FORMACIÓN SIS.',
      buttonLink: '/trabalhe-conosco/jovem-aprendiz',
      buttonContent: 'ACCEDE A LAS VACANTES ',
    },
  },

  text4: ['Premisas fundamentales del Programa', 'de Formación SIS'],

  text5: '✔ Ofrecer profesionales cualificados a nuestros clientes entendiendo el momento actual del mercado.',

  text6:
    '✔ Invertir en la selección, contratación, formación y retención de estudiantes en entrenamiento y jóvenes aprendices.',

  text7: '✔ Desarrollar entrenamientos adecuados a las necesidades del mercado para que seamos más competitivos.',

  icons: {
    item1: {
      title: 'Público objetivo',
      text: ['Pasantes |Estudiantes en entrenamiento | Jóvenes Aprendices'],
      icon: 'img2.webp',
    },
    item2: {
      title: 'MÓDULOS',
      text: ['Técnico | conductual | Prácticas Ágiles y Liderazgo | Negocio '],
      icon: 'img3.webp',
    },
    item3: {
      title: 'PLANO DE DESENVOLVIMENTO',
      text: [
        'hasta 02 años con la posibilidad de contratación efectiva a partir del 7º mes. Reajuste por desempeño cada 6 meses.',
        'Duración:',
      ],
      icon: 'img6.webp',
    },
  },

  text8: 'Testimonios',

  text9:
    'El Programa de Formación tiene gran valor en SIS, lo que nos llena de orgullo y nos hace sentir seguros de que estamos en el buen camino:',

  text10: 'Colaboración:',
}
