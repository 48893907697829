import consulting from './consulting'
import bi from './bi'
import cloud from './cloud'
import crmSolutions from './crmSolutions'
import development from './development'
import erp from './erp'
import lowCode from './lowCode'
import quality from './quality'
import sustentation from './sustentation'
import hunting from './hunting'

export default {
  solutions: {
    bi: {
      ...bi,
    },

    cloud: {
      ...cloud,
    },

    consulting: {
      ...consulting,
    },

    crmSolutions: {
      ...crmSolutions,
    },

    development: {
      ...development,
    },

    erp: {
      ...erp,
    },

    lowCode: {
      ...lowCode,
    },

    quality: {
      ...quality,
    },

    sustentation: {
      ...sustentation,
    },

    hunting: {
      ...hunting,
    },
  },
}
