export default {
  item1: 'Technology',
  item2: 'Innovation',
  item3: 'News',
  item4: 'Tips',
  item5: 'Training',
  item6: 'Services',
  item7: 'Find out what SIS can offer',

  item8: 'About us',
  item9: 'Success Cases',

  item10: 'Would you like to be part of the SIS team?',
  item11: 'Check out our job opportunities',

  buttonReadMore: 'READ MORE',
}
