export default {
  banner: ['Ud. en', 'SIS', '¡Venga a formar parte de nuestro equipo!'],
  buttonMobile: 'FILTROS',
  filters: {
    item1: 'Modelo de trabajo',
    item2: 'Nivel profesional',
    item3: 'Áreas de interés',
    item4: 'Tecnologías',
    item5: 'Banco de Talentos',
  },

  card: {
    text1: 'Beneficios:',
    buttonContent: 'Regístrese',
  },

  notFound: {
    text1: 'La oportunidad de trabajo que seleccionó no está disponible.',
    text2: '¡Regístrese en nuestro banco de talentos para futuras oportunidades!',
    text3: 'Regístrese',
  },

  buttonContent: 'CARREGAR MAIS',
}
